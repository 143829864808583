import { useMutation } from "react-query";
import { useQuery } from "react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import axios from "axios";
import { useEffect } from "react";
import moment from "moment";

export const StartupForm = () => {
    return useMutation(
      (fields) => {
        return axios({
          method: "post",
          url: `${process.env.REACT_APP_API_BASE_URL}/admin/master-startup`,
          headers: {
            // 'Authorization': `Bearer ${Cookies.get('jwtTokenemailVerify')}`
            'Content-Type': 'application/json'
          },
          data: fields,
        });
      },
      {
        /*refetchOnWindowFocus: false,
         staleTime: Infinity,*/
        // retry: 0,
        onSuccess: (data) => {
            // toast.success("Some error occured,Contact to admin");
            localStorage.setItem('startup_id', data.data.data._id);
        //   toast.success("Pin sent successfully on email");
        //   navigate("/login");
        },
        onError: (err)=>{
            toast.error(err);
            // return verifyToken(err)
          },
      }
    );
  };



//   export const EmailValidate = ({email}) => {
//     return useMutation(({payload,id}) => { 
//       async() => {
//         return await axios({
//           method: 'get',
//           url: `${process.env.REACT_APP_API_BASE_URL}/admin/master-startup/check-email-exists/${email}`,
//           headers: { 
//             'Authorization': `Bearer ${Cookies.get('jwtToken')}`
//           },
//         }) 
//        },  {
//       select: (data) => {
//           return data.data.data;
//       },
//       refetchOnWindowFocus: false,
//   },)
// }

export const EmailValidate = () => {
  return useMutation((email) => {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}/admin/master-startup/check-email-exists/${email}`,
      headers: { 
        // 'Content-Type': 'multipart/form-data'
      },
    })
    }, {
      select: (data) => {
          return data.data.data;
      },
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      toast.error(err);
      // return verifyToken(err)
    },
  }) 
}


  

  export const AddDocument = () => {
    return useMutation(({payload,id}) => {
      return axios({
        method: 'put',
        url: `${process.env.REACT_APP_API_BASE_URL}/admin/master-startup/upload/${id}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`,
          'Content-Type': 'multipart/form-data'
        },
        data:payload
      })
    }, {
      // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
      // retry: (failureCount, error)=>{
      //   return RetryOnError(failureCount, error)
      // },
      retry: 0,
      onError: (err)=>{
        toast.error(err);
        // return verifyToken(err)
      },
    }) 
  }
  
  export const AttachExcelReportAPI = () => {
    return useMutation(({payload}) => {
      return axios({
        method: 'put',
        url: `${process.env.REACT_APP_API_BASE_URL}/startup-app/excel-report/attach-excel`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('apiToken')}`,
          'Content-Type': 'application/json'
        },
        data:payload
      })
    }, {
      // retry: (failureCount, error)=>{
      //   return RetryOnError(failureCount, error)
      // },
      retry: 0,
      onError: (err)=>{
        toast.error(err);
        // return verifyToken(err)
      },
    }) 
  }


  export const FetchExcelReport = () => {
    return useQuery(['fetch-account-types-excel'], 
      async() => {
        return await axios({
          method: 'get',
          url: `${process.env.REACT_APP_API_BASE_URL}/startup-app/excel-report/get-startup-report`,
          headers: { 
            'Authorization': `Bearer ${Cookies.get('apiToken')}`
          },
        }) 
    }, {
      select: (data) => {
          return data.data.data;
      }})
}


export const AttachMisReportAPI = (misReportId) => {
  return useMutation(({payload}) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_BASE_URL}/startup-app/mis-report/document/save/${misReportId != undefined ? misReportId : ""}`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('apiToken')}`,
         'Content-Type': 'multipart/form-data'
      },
      data:payload
    })
  }, {
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      toast.error(err);
      // return verifyToken(err)
    },
  }) 
}
export const MSIDownloadDocument = () => {
  return useMutation(({payload}) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_BASE_URL}/startup-app/mis-report/document/get-secure-url`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('apiToken')}`,
         'Content-Type': 'application/json'
      },
      data:payload
    })
  }, {
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      toast.error(err);
      // return verifyToken(err)
    },
  }) 
}

export const FetchReportListing = () => {
  return useQuery(['fetch-report-listing'], 
    async() => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_BASE_URL}/startup-app/startup`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('apiToken')}`
        },
      }) 
  }, {
    select: (data) => {
        return data.data.data[0]?.misDocuments;
    }
  })
}

export const FetchReportDetails = (id) => {
  return useQuery(['fetch-report-listing-details',id], 
    async() => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_BASE_URL}/startup-app/mis-report/documents/list/${id}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('apiToken')}`
        },
      }) 
  }, {
    refetchOnWindowFocus: false,
            enabled: !!id,
    select: (data) => {
      // console.log(moment(data?.data?.data?.to_date),"data?.data?.data?.to_date");
        return {...data.data?.data, to_date: moment(data?.data?.data?.to_date), from_date: moment(data?.data?.data?.from_date)};
    }})
}

export const DeleteDocumentForMIS = () => {
  return useMutation(
      ({ payload, id }) => {
          return axios({
              method: 'delete',
              url: `${process.env.REACT_APP_API_BASE_URL}/startup-app/mis-report/delete-document/${id}`,
              headers: {
                  Authorization: `Bearer ${Cookies.get('apiToken')}`,
                  'Content-type': 'application/json'
              },
              data: payload
          });
      },
      {
          retry: 0,
          // onSuccess: (data)=>console.log("API successfull"),
          // retry: (failureCount, error)=>{
          //   return RetryOnError(failureCount, error)
          // },
          onError: (err) => {
            console.log(err,"error")
              // return verifyToken(err);
          }
      }
  );
};