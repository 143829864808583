import React from "react";
import CommonLayout from "../layouts/CommonLayout";
import Logout from "../views/auth/Logout";
import Application from "../views/main/Application";
import ChecklistListing from "../views/main/Checklist/ChecklistListing";
import ContactUs from "../views/main/ContactUs";
import Dashboard from "../views/main/DashBoard";
import UserDetailsView from "../views/main/DetailsView/UserDetailsView";
import MyProfile from "../views/main/MyProfile";
import StartupDetailsView from "../views/main/StartupDetailsView";
// import Report from "../views/main/Report/ExcelReport";
import Report from "../views/main/Report/MisReport";
import ChangePIN from "../views/main/ChangePIN";
import ReportListing from "../views/main/Report/ReportListing"
import MisReport from "../views/main/Report/MisReport";
const MainRoutes = {
    path: "",
    element: <CommonLayout />,
    children: [
        {
            path: '',
            element: <Application />
        },

        {
            path: "*",
            element: <Application />,
        },
        {
            path: 'logout',
            element: <Logout />
        },
        {
            path: 'my-profile',
            element: <MyProfile />
        },
        {
            path: 'startup-details',
            element: <StartupDetailsView />
        },
        {
            path: 'user-details-view',
            element: <UserDetailsView />
        },
        {
            path: 'fund-raise',
            children: [
                {
                    path: 'application',
                    element: <Application />
                },
            ]
        },
        {
            path:'reports',
            children:[
                {
                    path:'',
                    element:<ReportListing/>
                },
                {
                    path:'create-report',
                    element:<MisReport/>
                },
                {
                    path:'create-report/:id',
                    element:<MisReport/>
                }
            
            ]

        },
        {
            path: 'checklist',
            children: [
                {
                    path: '',
                    element: <ChecklistListing />
                },
            ]
        },
        {
            path: 'contact-us',
            children: [
                {
                    path: '',
                    element: <ContactUs />
                },
            ]
        },
        // {
        //     path: 'report',
        //     children: [
        //         {
        //             path: '',
        //             element: <Report />
                    
        //         },
        //         {
        //             path: ':id',
        //             element: <Report />
                    
        //         },
        //     ]
        // },
        {
            path: 'reports',
            element: <ReportListing />
        },
        {
            path: 'change-pin',
            element: <ChangePIN />
        },

    ],

};

export default MainRoutes;