import React, { useEffect, useState } from "react";

function PreScreening({setStep}) {
    return (
     <section className="mid-wrap pt-5 pb-5">
        <div className="mid-card-block">
          <div className="mxw-520">
            {/* <div className="text-center mb-4 vendor-info">
              <img src={logoVr} alt="my mandi" />
            </div> */}

            <h5 className="card-title lg-title mb-4 fw-700">Pre-Screening Application Pre-requisites</h5>
            <div className="card form-card">
              <div className="card-body">
                {/* <h5 className="card-title lg-title">OTP Verification</h5> */}
                <ul className="pre-screen-list text-start">
                  <li>Fill this form only if you are one of the founders / co-founders of the startup.</li>
                  <li>A pitch deck (PDF format) up to 5MB</li>
                  <li>A video pitch of maximum 3 minutes (Good to have)</li>
                  <li>Demo video URL of the product</li>
                </ul>
                <button className="theme-btn btn w-100" onClick={()=> setStep(3)} type="button">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
    </section>
    );
  }


  export default PreScreening;
