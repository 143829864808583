import React from 'react';

import Login from '../views/auth/Login';
import MainLayout from '../layouts/MainLayout';
import ForgotPin from '../views/auth/ForgotPin';
import { Logout } from '../views/auth/Logout';
import StartupFormRoutes from './StartupFormRoutes';
import Home from '../views/StartupForms/Home';


const AuthRoutes = {
    path: '',
    element: <MainLayout />,
    children: [

        {
            path: '',
            element: <Login />
        },
        {
            path: 'login',
            element: <Login />
        },
        {
            path: 'forgot-pin',
            element: <ForgotPin />
        },
        {
            path: 'register',
            element: <Home />
        },
        {
            path: 'logout',
            element: <Logout />
        },
        {
            path: "*",
            element: <Login />,
        },
    ],


};

export default AuthRoutes;