import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
// import config from 'config';
import Cookies from 'js-cookie';
import { Realtime } from '../assets/img';
import config from '../config';
// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath} className='main-logo'>
        {process.env.REACT_APP_SERVER && process.env.REACT_APP_SERVER == "demo" ?  <img src={Realtime} alt='Backrr Logo'/> : <img src={`https://realtimeinvestmentappprod.s3.ap-south-1.amazonaws.com/fund/app/logo.png?time=`+Date.now()} alt="Logo"/>}
        
    </ButtonBase>
);

export default LogoSection;