import checkIcon from '../../assets/img/correct.png'

const Thankyou = () => {
  return (
    <>
      <section className="mid-wrap pt-5 pb-5">
        <div className="mid-card-block">
          <div className="mxw-650 ms-auto me-auto">

            <div className="card form-card mt-4">
              <div className="card-body">
                <div className="text-center mb-4 check-icon">
                  <img src={checkIcon} alt="check" />
                </div>
                <h5 className="card-title lg-title mb-4 fw-700">Thank You, {localStorage.getItem('user_name')}</h5>
                <p className="sm-title font-1-2em mt-0 lh-1-5em">
                  We have received your application. </p>
                <p className="sm-title font-1-2em mt-0 lh-1-5em">Our team will be doing pre-screening of your application and once we are convinced with your details we will send you a link for a detail elevation form which will evaluated by our investment advisory board.
                </p>
                <p className="sm-title font-1-2em mb-5 mt-0 lh-1-5em">If you have any queries, you can write to us at  <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Thankyou