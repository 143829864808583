import { types } from '../actions/types';

const initialState = {
    data: {},
};

const setMenuReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_MENU:
            return {
                ...state,
                data: payload,
            };
        default:
            return state;
    }
};

export default setMenuReducer