import {types} from './types';

export function contactUs(data) {
  return {
    type: types.SEND_REQUEST_CONTACT_US,
    payload: data,
  };
}
export function contactUsSuccess(data) {
  return {
    type: types.SEND_REQUEST_CONTACT_US_SUCCESS,
    payload: data,
  };
}

export function contactUsFailure(error) {
  return {
    type: types.SEND_REQUEST_CONTACT_US_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearContactUs() {
  return {
    type: types.CLEAR_REQUEST_CONTACT_US,
    payload: {},
  };
}