import { types } from '../actions/types';

const initialState = {
    data: [],
};

const menuOpenReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.MENU_OPEN:
            return {
                ...state,
                data: [payload],
            };
        default:
            return state;
    }
};

export default menuOpenReducer