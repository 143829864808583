import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Footer from './elements/Footer';
import Header from './elements/Header';



const MainLayout = () => {
    const domainName = window.location.hostname;
    var getDomainDetails = "Startup";
   
    return (
        <>
            <ToastContainer limit={1} />

            <Header getDomainDetails={getDomainDetails} />

            <Outlet />

            <Footer />
        </>
    );
};

export default MainLayout;
