import { Field, Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import Card from '../../components/Card'
import { getUserInfo } from '../../store/actions/getUserInfoAction'
import * as Yup from "yup";
import { CustomInputComponent, CustomTextareaComponent } from '../../components/formik/FieldComponents'
import { clearContactUs, contactUs } from '../../store/actions/contactUsAction'
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom'

export default function ContactUs() {

    const [userDetail, setUserDetails] = useState(null)
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    const recaptchaRef = useRef();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const getUserInfoResponse = useSelector(state => state.getUserInfoReducer.data);
    const contactUsResponse = useSelector(state => state.contactUsReducer.data);

    const ContactUsValidationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        phone_number: Yup.string().required("Phone number is required"),
        email_address: Yup.string()
            .required("Email is required")
            .email("Must be a valid email"),
        message: Yup.string().required("Message is required"),
    });

    useEffect(() => {
        dispatch(getUserInfo());
    }, [])

    useEffect(() => {
        if (getUserInfoResponse != null) {
            if (getUserInfoResponse.data != null) {
                setUserDetails({
                    fullName: getUserInfoResponse.data[0].company_name,
                    mob: getUserInfoResponse.data[0].mobile_number,
                    email: getUserInfoResponse.data[0].email,
                })
            }
        }
    }, [getUserInfoResponse]);

    const formik = useFormik({
        initialValues: {
            name: userDetail?.fullName,
            phone_number: userDetail?.mob,
            email_address: userDetail?.email,
            message: "",
        },
        validationSchema: ContactUsValidationSchema,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            // if (isCaptchaVerified) {
                sendContactUs(values);
            // } else {
            //     toast.error("Please complete the CAPTCHA challenge.");
            // }
        }
    });

    const onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    const sendContactUs = (values) => {
        let request = {
            name: values.name,
            email_address: values.email_address,
            phone_number: values.phone_number,
            message: values.message
        }
        dispatch(contactUs(request))
    }

    useEffect(() => {
        if (contactUsResponse != null) {
            if (contactUsResponse.message != null) {
                toast.success(contactUsResponse.message)
                dispatch(clearContactUs())
                navigate("/")
            }
        }
    }, [contactUsResponse]);
    const handleCaptchaChange = (token) => {
        setIsCaptchaVerified(true);
    }

    return (
        <>
            {/* <ToastContainer /> */}
            <Card style={{ paddingLeft: '16px', paddingRight: '16px' }} title={"Contact Us"} backTo={"/dashboard"}>
                <div className='card-body'>
                    <FormikProvider value={formik} sx={{ flex: 1 }}>
                        <Form
                            onSubmit={formik.handleSubmit}
                            onKeyDown={onKeyDown}
                            className="custom-form"
                        >
                            <div className='row'>
                                <div className="col-md-6 form-group">
                                    <label className="required">Startup Name</label>
                                    <Field
                                        name="name"
                                        component={CustomInputComponent}
                                        type="text"
                                        disabled={true}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label className="required">Email</label>
                                    <Field
                                        name="email_address"
                                        component={CustomInputComponent}
                                        type="text"
                                        disabled={true}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label className="required">Mobile Number</label>
                                    <Field
                                        name="phone_number"
                                        component={CustomInputComponent}
                                        type="text"
                                        disabled={true}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-12 form-group">
                                    <label className="required">Message</label>
                                    <Field
                                        name="message"
                                        component={CustomTextareaComponent}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                {/* <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey="6LdWb4kpAAAAAIaaYf2NxwZTmtRfvhmAEYbkfs-N"
                                    onChange={handleCaptchaChange}

                                /> */}
                            </div>

                            <div className="mt-3 text-center">
                                <button type="submit" className="theme-btn btn w-25">
                                    Send
                                </button>
                            </div>
                        </Form>
                    </FormikProvider>
                </div>
            </Card>
        </>
    )
}
