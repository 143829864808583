import { ColorRing } from  'react-loader-spinner'
import { useTheme } from '@mui/material/styles';

function Loading({style, height="80", width="80"}) {
  const theme = useTheme()
  return (
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', ...style}}>
          <ColorRing
            visible={true}
            height={height}
            width={width}
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
          />
        </div>
  )
}

export default Loading