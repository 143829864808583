import Cookies from 'js-cookie';
import { useRoutes } from 'react-router-dom';
import AuthRoutes from './AuthRoutes';
import MainRoutes from './MainRoutes';
import PagesRoutes from './PagesRoutes';
// routes

import StartupFormRoutes from './StartupFormRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {

      let routesArr;

      if (Cookies.get('apiToken') !== "" && Cookies.get('apiToken') !== undefined) {
            routesArr = [MainRoutes];
      } else {
            routesArr = [AuthRoutes];
      }
      return useRoutes([...routesArr]);
}
