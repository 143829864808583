import React, { useState, useEffect } from "react";
import { Field, Form, Formik, FastField } from "formik";
import * as Yup from "yup";
import BtnSpinner from "../../utils/BtnSpinner";
import {
  CustomRadioComponent,
  FileInputComponent,
  CustomInputComponent,
} from "../../components/formik/FieldComponents";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   UPDATE_PERSONALISED_INFORMATION,
//   SET_PAGE,
// } from "../../../../store/actions";
import { SavePersonalInformation, GetSectors, StartupForm, AddDocument } from "../../api";
import { Back } from "../../assets/img";

const PersonalInformationValidationSchema = Yup.object().shape({
  video: Yup.mixed().required('Please upload pitch deck')
    .test('fileFormat', 'Only PDF files are allowed', value => {
      if (value) {
        const supportedFormats = ['pdf'];
        return supportedFormats.includes(value[0].name.split('.').pop());
      }
      return true;
    })
    .test('fileSize', 'File size must be less than 5MB', value => {
      if (value) {
        return value.size <= 5, 242, 880;
      }
      return true;
    }),
  // videoData: Yup.string().url("Please enter a valid URL"),
})



const personalInformation = {
  stage: "",
  video: "",
  videoData: [{ "heading": 'Myfile', "url": '' }],
  valuation_value: "",
  valuation_type: "",
  valuation_annual_revenue: ""
};

function OrtherDetail({ setStep }) {
  const navigate = useNavigate();
  const { data: Secotrs, isLoading: isContriesLoading } = GetSectors();
  const { mutateAsync: uploadFileFolder, status: uploadFileFolderStatus, error: uploadFileFolderError } = AddDocument();

  const valuationTypes = [
    {
      key: 'Revenue',
      value: 'Revenue',
      _id: 'Revenue'
    },
    {
      key: 'Pre-Revenue',
      value: 'Pre-Revenue',
      _id: 'Pre-Revenue'
    }
  ]


  const [ischeckValidate, setcheckValidate] = React.useState(true);
  const [ischeckValidatefileAddressProof, setcheckValidatefileAddressProof] =
    React.useState(true);
  const {
    mutateAsync: startUpData,
    status: otpStatus,
    error,
    isLoading,
  } = StartupForm();

  //   useEffect(() => {
  //     if (
  //       !isDocsLoading &&
  //       docs.fileIdentityProof &&
  //       docs.fileIdentityProof.length > 0
  //     ) {
  //       setcheckValidate(false);
  //     } else {
  //       setcheckValidate(true);
  //     }

  //     if (
  //       !isDocsLoading &&
  //       docs.fileAddressProof &&
  //       docs.fileAddressProof.length > 0
  //     ) {
  //       setcheckValidatefileAddressProof(false);
  //     } else {
  //       setcheckValidatefileAddressProof(true);
  //     }
  //   }, [isDocsLoading, docs]);
  useEffect(() => {
    if (uploadFileFolderStatus == 'success') {
      setStep(6)
    }
    if (uploadFileFolderStatus == 'error') {
      toast.error(uploadFileFolderError?.response?.data?.message);
    }
  }, [uploadFileFolderStatus])
  return (
    <section className="mid-wrap">
      <div className="forms-header pt-3 pb-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-2 col-2">
              <Link className="back-arrow">
                <img onClick={() => setStep(4)} src={Back} alt="back" />
              </Link>
            </div>
            <div className="col-md-8 col-8">
              <div className="lg-title text-center">Other Information</div>
            </div>
            {/* <div className="col-md-2 col-2 text-end">
              <span className="pl-15 sm-title">3/4</span>
            </div> */}
          </div>
          <div className="text-center step-process mt-1">
            <div className="d-inline-flex">
              <span className="process-step active"></span>
              <span className="process-step active"></span>
              <span className="process-step selected"></span>
              <span className="process-step"></span>
             
            </div>
          </div>
          {/* <h5 className="card-title md-title mt-2 mb-0 text-center heading-formobile">
            Personal Information
          </h5> */}
        </div>
      </div>
      <div className="pt-0 pb-5 kyc-form-block">
        <div className="container">
          <div className="card form-card mt-4">
            <div className="card-body">
              {/* <h5 className="card-title md-title mb-4 text-center heading-fordesktop">
                Personal Information
              </h5> */}
              <Formik
                initialValues={personalInformation}
                // validateOnChange={false}
                // validateOnBlur={true}
                enableReinitialize={true}
                validationSchema={PersonalInformationValidationSchema}
                onSubmit={(values) => {
                  if (values.stage != "" || values.videoData != "" || values.valuation_value != "" || values.valuation_type != "" || values.valuation_annual_revenue != "") {
                    const storedUserObject = localStorage.getItem('user_details');
                    const UserObject = JSON.parse(storedUserObject)
                    let data = { "email": UserObject.email };
                    if (values.stage != "") {
                      data = { ...data, ...{ "stage": values.stage, "videoData": values.videoData } };
                    }
                    if (values.videoData != "") {
                      data = { ...data, ...{ "videoData": values.videoData } };
                    }
                    if (values.valuation_type != "") {
                      data = { ...data, ...{ "valuation_type": values.valuation_type } };
                    }
                    if (values.valuation_value != "") {
                      data = { ...data, ...{ "valuation_value": values.valuation_value } };
                    }
                    if (values.valuation_annual_revenue != "") {
                      data = { ...data, ...{ "valuation_annual_revenue": values.valuation_annual_revenue } };
                    }
                    console.log("data", data)

                    startUpData(data)

                  }

                  // console.log(values,"valuesvaluesvaluesvaluesvaluesvaluesvalues")
                  const formData = new FormData();
                  const id = localStorage.getItem('startup_id');

                  if (values.video) {
                    formData.append("document", values.video[0]);
                    uploadFileFolder({ payload: formData, id })
                  }

                  // console.log(values,"stagestagestagestagestagestagestagestagestage")
                  // setStep(6)     
                }}
              >
                {({ errors, touched, values }) => (
                  <Form className="custom-form">
                    <div className="row">
                      {/* First Name */}

                      {!isContriesLoading &&
                        <div className="col-12 form-group">

                          What is the Stage of Your Startup?

                          <div className="startup-radio-list">
                            <Field
                              name="stage"
                              component={CustomRadioComponent}
                              options={Secotrs}
                            />
                          </div>
                        </div>}

                      <div className="col-md-6 form-group">
                        <label className="required">Upload Pitch Deck</label>
                        <div className="custom-upload">
                          <button className="custom-upload-btn">
                            Upload Pitch Deck
                          </button>
                          <Field
                            name="video"
                            component={FileInputComponent}
                            label="Proof of Identity"
                          // desc="*Upload self attested document"
                          // checkFileExixtsORNot={(fileIdentityProofDocs) => {
                          //   if (
                          //     fileIdentityProofDocs &&
                          //     fileIdentityProofDocs.length > 0
                          //   ) {
                          //     setcheckValidate(false);
                          //   } else {
                          //     setcheckValidate(true);
                          //   }
                          // }}

                          />
                        </div>
                      </div>

                      {/* Email Address */}
                      <div className="col-md-12 form-group">
                        <label>Have an Elevator Pitch? (optional)</label>
                        <Field
                          name={`videoData.${0}.url`}
                          component={CustomInputComponent}
                          type="link"
                          placeholder="This can be a google drive, video or youtube (unlisted) link"
                        />
                      </div>


                      <div className="col-12 form-group">

                        What is the type of Your Startup Valuation?

                        <div className="startup-radio-list">
                          <Field
                            name="valuation_type"
                            component={CustomRadioComponent}
                            options={valuationTypes}
                          />
                        </div>
                      </div>

                      {
                        values.valuation_type != "" &&
                        <div className="col-md-12 form-group">
                          <label>Valuation (₹)</label>
                          <Field
                            name={`valuation_value`}
                            component={CustomInputComponent}
                            type="number"
                            placeholder={`Enter valuation of your ${values.valuation_type}`}
                            onWheel={(e) => e.target.blur()}
                          />
                        </div>
                      }

                      {
                        values.valuation_type == "Revenue" &&
                        <div className="col-md-12 form-group">
                          <label>Annual Revenue (₹)</label>
                          <Field
                            name={`valuation_annual_revenue`}
                            component={CustomInputComponent}
                            type="number"
                            placeholder={`Enter annual revenue`}
                            onWheel={(e) => e.target.blur()}
                          />
                        </div>
                      }

                      {/* Occupation */}



                      {/* Country of citizenship */}
                      {/* <div className="col-md-6 form-group">
                        <label className="required">
                          Select Individual Type
                        </label>
                        <Field
                          name="individual_type"
                          component={CustomSelectComponent}
                          options={IndianCitizenUserType.individual_type}
                        />
                      </div> */}

                      {/* NRI Checkbox */}
                      {/* <div className="col-md-12 form-group">
                        <div className="react-checkbox">
                          <Field
                            name="nriCheckbox"
                            component={CustomCheckbox}
                            label="Are you a Non-Resident Indian (NRI)?"
                          />
                        </div>
                      </div> */}
                    </div>
                    {/* {isSavePersonalInformationLoading ? (
                      <BtnSpinner />
                    ) : ( */}
                    <div className="mt-3 text-center">
                      <button type="submit" className="theme-btn btn w-25">
                        Next
                      </button>
                    </div>
                    {/* )} */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          {/* <div className="text-center mt-30">
            <a href="#" className="white-btn w-360">
              Terms of Use
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default OrtherDetail;
