import { Grid, Box, Typography, Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import MainCard from './MainCard';
import backArrow from "../assets/img/back.svg"

function Card({ handleClose, title, children, style = {}, backTo, secondary }) {
	const theme = useTheme();
	return (
		<MainCard
			border={false}
			// elevation={16}
			content={false}
			// boxShadow
			// shadow={theme.shadows[16]}
			sx={{
				width: '100%',
				// marginBottom: '16px',
				borderRadius: '8px'
			}}
			handleClose={handleClose}
		>
			<Grid container item xs={12} direction="column" spacing={2} id="1">
				{title && <Grid item xs={12} id="2">
					<Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
						{/* <Grid item>
							<Stack direction="row" spacing={2}>
								<div className='header-mobile d-md-none'>
									<div className='d-flex align-items-center justify-content-between'>
										<div>
											<Link className="back-btn blue-text d-block" to={backTo}>
												<img src={backArrow} alt="arrow" /> {title}
											</Link>
										</div>
									</div>
								</div>

								<div className='header-desktop'>
									<div className="md-title text-start fw-700">
										{title}
									</div>
								</div>
							</Stack>
						</Grid> */}
						<Grid item xs={secondary ? 6 : 12}>
							<Stack justifyContent="flex-start" spacing={1}>
								<div className='header-mobile d-md-none'>
									<div className='d-flex align-items-center justify-content-between'>
										<div>
											<Link className="back-btn blue-text d-block" to={backTo}>
												<img src={backArrow} alt="arrow" /> {title}
											</Link>
										</div>
									</div>
								</div>

								<div className='header-desktop'>
									<div className="md-title text-start fw-700">
										{title}
									</div>
								</div>
							</Stack>
						</Grid>
						{secondary && <Grid item xs={6}>
							<Stack justifyContent="flex-end" spacing={1}>
								{secondary}
							</Stack>
						</Grid>}
					</Grid>
				</Grid>}
				<Grid item xs={12} id="3" sx={{ width: '100%' }}>
					<Box>
						<Grid container direction="column" spacing={2}>
							<Grid item xs={12} p={0} id="4">
								<Divider sx={{ my: 0 }} />
							</Grid>
						</Grid>
						<Box sx={{ padding: '10px 20px 20px 20px', marginBottom: '16px', mt: 1.5, ...style }}>
							{children}
						</Box>
					</Box>
				</Grid>
			</Grid>
		</MainCard>
	)
}

export default Card