
import { TextField, Paper, Box, Button, TableCell, TableContainer, Table, TableHead, TableRow, IconButton, TableBody } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles, withStyles } from '@mui/styles';
import 'react-toastify/dist/ReactToastify.css';
import Clickable from '../../../utils/Clickable'
import pdfIcon from '../../../assets/img/pdf.png'
import xlsxIcon from '../../../assets/img/xlsx.png'
import jpgIcon from '../../../assets/img/jpg.png'
import pptIcon from '../../../assets/img/ppt.png'
import { MSIDownloadDocument } from '../../../api';
import pngIcon from "../../../assets/img/png.png"
import doc2Icon from '../../../assets/img/doc2.png'
const useStyles = makeStyles({
    customTable: {
        '& td.MuiTableCell-root': { padding: '10px 16px 10px 16px' },
        '& .MuiTableCell-head': {
            color: 'white',
            backgroundColor: '#0000FF'
        }
    }
});

const PrivateDocumentPopup = ({ data }) => {
    const theme = useTheme()
    const classes = useStyles();
    const { mutateAsync: downloadDocument } = MSIDownloadDocument();

    const handleDownload = async (fileName, folderName, event) => {
        event.preventDefault();
        try {
          const response = await downloadDocument({ payload: { file_name: fileName ,folder_name: folderName} });
          const downloadUrl = response.data.data.url;
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.download = fileName;
          link.target = '_blank';
          link.click();
        } catch (error) {
          // console.error('download_pdf', error);
        }
      };
      const fileExtensionSwitch = (value) => {
        switch (value) {
            case 'docx': return doc2Icon
            case 'xlsx': return xlsxIcon
            case 'xls': return xlsxIcon
            case 'png': return pngIcon
            case 'jpeg': return jpgIcon
            case 'jpg': return jpgIcon
            case 'pdf': return pdfIcon
            case 'ppt' : return pptIcon
            default: return pdfIcon
        }
    };

    const columns = [
        {
            label: 'Document Name',
            minWidth: 100,
            align: 'left',
            type: 'name',
         },
        {
            label: 'Document Type',
            minWidth: 100,
            align: 'left',
            type: 'document_type_format'
          },
        {
          label: 'File',
          minWidth: 50,
          align: 'left',
          type: 'file_name',
        },
        
      ];

      const documents_types = [
        { label: "The Summary Report", value: "the_summary_report" },
        { label: "Trend Report", value: "trend_report" },
        { label: "Monthly MIS Report", value: "monthly_mis_report" },
        { label: "Exception Reports", value: "exception_reports" },
        { label: "Financial MIS Report", value: "financial_mis_report" },
        { label: "Inventory MIS Report", value: "inventory_mis_report" },
        { label: "Sales MIS Report", value: "sales_mis_report" },
        { label: "Budget MIS Report", value: "budget_mis_report" },
        { label: "Fund Flow Statement Report", value: "fund_flow_statement_report" },
        { label: "Actual Vs Budget MIS Report", value: "actual_vs_budget_mis_report" },
        { label: "Others", value: "others" },
      ]
        const reportListingData = data?.map((row) => ({
    ...row,
    document_type_format: row.document_type === "others" ? `Others ${row?.specify != "" ? "(" + row?.specify + ")" : ""}` : documents_types?.find(item => item?.value === row.document_type)?.label,
  }));

    
    return (
        <>
            <Paper sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '10px',  overflowY: "auto", maxHeight: "90vh" ,}} >
            <h3 style={{ textAlign: 'center',    display: 'block', fontSize: "1.17rem", marginBlock: '1rem', marginInline: 0, fontWeight: "bold", unicodeBidi: "isolate" }}>Documents</h3>
                {/* <ul className="document-list mt-2">
                    {data?.map((item, index) => (
                        <>
                            <li className="d-flex align-items-center">
                                <Clickable className="icon" onClick={(event) => handleDownload(item.file_name, "startup/mis_report",event)}>
                                <img className='ud-icon'
                                // src={pdfIcon} 
                                // src={fileExtensionSwitch(item?.type)}
                                src={fileExtensionSwitch(item?.file_name?.split('.').pop()) }
                                alt="file"
                                />
                                </Clickable>
                                <Clickable className="ms-2" onClick={(event) => handleDownload(item.file_name, "startup/mis_report", event)}>
                                 <div className="xs-title font-16 text-start">{item.name}</div>
                                 <div className="sm-title font-12 text-start mt-1">{item.date_format}</div>
                               </Clickable>
                            </li>
                        </>
                    ))}
                </ul> */}
                <TableContainer>
                    <Table sx={{minWidth: 550}} className={`${classes.customTable} add-border deal-table`} aria-label="customized table" stickyHeader>
                        <TableHead sx={{ position: 'sticky', top: '-1px', zIndex: 3 }}>
                            <TableRow
                                sx={{
                                    '& .MuiTableCell-sizeMedium': {
                                        padding: '10px 16px 10px 16px' // <-- arbitrary value
                                    }
                                }}
                            >
                                {columns.map((column, index) => (
                                    <TableCell key={index} align={column.align} style={{
                                        minWidth: column.minWidth,
                                        position: index === column.fixed_row_index ? 'sticky' : 'relative',
                                        left: index === column.fixed_row_index ? 0 : 'auto',
                                        zIndex: index === column.fixed_row_index ? 2 : 'auto',
                                        backgroundColor: '#0000FF',
                                    }} padding={'normal'}>
                                            {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportListingData?.map((row, idx) => {
                                // console.log("row", row)
                                return <>
                                    <TableRow key={idx}>
                                        {
                                            Object.keys(columns)?.map((_, index) =>{
                                                if(columns[index].type === "file_name"){
                                                    return <TableCell>
                                                        {/* {/ <div className="d-flex align-items-center"> /} */}
                                                            <Clickable className="icon" onClick={(event) => handleDownload(row.file_name, "startup/mis_report",event)}>
                                                                <img className='ud-icon'
                                                                style={{width: 30}}
                                                                // src={pdfIcon} 
                                                                // src={fileExtensionSwitch(row?.type)}
                                                                src={fileExtensionSwitch(row?.file_name?.split('.').pop()) }
                                                                alt="file"
                                                                />
                                                            </Clickable>
                                                            {/* <Clickable className="ms-2" onClick={(event) => handleDownload(row.file_name, "startup/mis_report", event)}>
                                                                <div className="xs-title font-16 text-start">{row.file_name}</div>
                                                                <div className="sm-title font-12 text-start mt-1">{row.date_format}</div>
                                                            </Clickable> */}
                                                        {/* {/ </div> /} */}
                                                    </TableCell>
                                                }
                                                return <TableCell>
                                                    {row[columns[index].type]}
                                                </TableCell>
                                            })
                                        }

                                    </TableRow>
                                </>
                            })}
                        </TableBody>

                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}

export default PrivateDocumentPopup;
