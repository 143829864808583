import { put, call, takeEvery } from 'redux-saga/effects';
import { types } from '../actions/types';
import { changePIN, contactUs, forgotPIN, getUserInfo, login } from '../apis/Api';

function* getUserInfoSaga({ payload, error }) {
    try {
        const response = yield call(getUserInfo, payload);
        yield put({
            type: types.SEND_REQUEST_GET_USER_INFO_SUCCESS,
            payload: response,
        });
    } catch (err) {
        yield put({
            type: types.SEND_REQUEST_GET_USER_INFO_FAILURE,
            payload: error,
        });
        console.log(err);
    }
}

function* loginSaga({ payload, error }) {
    try {
        const response = yield call(login, payload);
        yield put({
            type: types.SEND_REQUEST_LOGIN_SUCCESS,
            payload: response,
        });
    } catch (err) {
        yield put({
            type: types.SEND_REQUEST_LOGIN_FAILURE,
            payload: error,
        });
        console.log(err);
    }
}

function* forgotPINSaga({ payload, error }) {
    try {
        const response = yield call(forgotPIN, payload);
        yield put({
            type: types.SEND_REQUEST_FORGOT_PIN_SUCCESS,
            payload: response
        });
    } catch (err) {
        yield put({
            type: types.SEND_REQUEST_FORGOT_PIN_FAILURE,
            payload: error
        });
        console.log(err);
    }
}

function* contactUsSaga({ payload, error }) {
    try {
        const response = yield call(contactUs, payload);
        yield put({
            type: types.SEND_REQUEST_CONTACT_US_SUCCESS,
            payload: response
        });
    } catch (err) {
        yield put({
            type: types.SEND_REQUEST_CONTACT_US_FAILURE,
            payload: error
        });
        console.log(err);
    }
}

function* changePINSaga({ payload, error }) {
    try {
        const response = yield call(changePIN, payload);
        yield put({
            type: types.SEND_REQUEST_CHANGE_PIN_SUCCESS,
            payload: response
        });
    } catch (err) {
        yield put({
            type: types.SEND_REQUEST_CHANGE_PIN_FAILURE,
            payload: error
        });
        console.log(err);
    }
}

export default function* saga() {
    yield takeEvery(types.SEND_REQUEST_GET_USER_INFO, getUserInfoSaga);
    yield takeEvery(types.SEND_REQUEST_LOGIN, loginSaga);
    yield takeEvery(types.SEND_REQUEST_FORGOT_PIN, forgotPINSaga);
    yield takeEvery(types.SEND_REQUEST_CONTACT_US, contactUsSaga);
    yield takeEvery(types.SEND_REQUEST_CHANGE_PIN, changePINSaga);
}