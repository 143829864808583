import React, { useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import UserDetailsView from './DetailsView/UserDetailsView';

export default function StartupDetailsView() {
  const [step_num, setStep] = useState(1);

  if (step_num == 1) {
    return (
      <DashboardLayout>
        <UserDetailsView setStep={setStep} />
      </DashboardLayout>
    )
  } 
  // else if (step_num == 2) {
  //   return (
  //     <DashboardLayout>
  //       <StartupDetails setStep={setStep} />
  //     </DashboardLayout>
  //   )
  // } else if (step_num == 3) {
  //   return (
  //     <DashboardLayout>
  //       <OtherDetails setStep={setStep} />
  //     </DashboardLayout>
  //   )
  // } else if (step_num == 4) {
  //   return (
  //     <DashboardLayout>
  //       <ReferalDetails setStep={setStep} />
  //     </DashboardLayout>
  //   )
  // }
}
