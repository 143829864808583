import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect } from "react";
import storage from "redux-persist/lib/storage";
import { useDispatch } from "react-redux";
import { menuOpen } from "../../store/actions/menuOpenAction";
import { setMenu } from "../../store/actions/setMenuAction";

export const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  Cookies.remove("apiToken");
  Cookies.remove("userData");
  Cookies.remove("verifyEmail");
  Cookies.remove('jwtTokenemailVerify');
  useEffect(() => {
    storage.removeItem("persist:root");
    Cookies.remove("userData");
    dispatch(menuOpen("application"))
    dispatch(setMenu(false))
    navigate("/login");
  }, []);
};

export default Logout;