import * as Yup from "yup";
import AuthBtnSpinner from "../../utils/AuthBtnSpinner";
import { Field, Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { clearLogin, login } from "../../store/actions/loginAction";
import storage from "redux-persist/lib/storage";
import { clearGetUserInfo, getUserInfo } from "../../store/actions/getUserInfoAction";

const LoginValidationSchema = Yup.object().shape({
    email: Yup.string()
        .required("Email is required")
        .email("Must be a valid email"),
    pin: Yup.string().required("Pin is required"),
});

const Login = () => {
    const [errorMsg, setErrorMsg] = useState(null);

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const loginResponse = useSelector(state => state.loginReducer.data)
    const getUserInfoResponse = useSelector(state => state.getUserInfoReducer.data)
    const loginLoading = useSelector(state => state.loginReducer.loading)
    const getUserInfoLoading = useSelector(state => state.getUserInfoReducer.loading)

    const loginAPI = async (values) => {
        let request = {
            email: values.email,
            pin: values.pin,
            device_id: "web",
            device_type: "web",
            device_token: "web"
        }
        dispatch(login(request))
    }

    useEffect(() => {
        if (loginResponse != null) {
            if (Object.keys(loginResponse).length != 0 && loginResponse.statusCode != 200) {
                toast.error(loginResponse.message)
                dispatch(clearLogin())
            }
            if (Object.keys(loginResponse).length != 0 && loginResponse.statusCode == 200) {
                saveData(loginResponse.data)
                dispatch(clearLogin())
            }
        }
    }, [loginResponse])

    const saveData = async (data) => {
        storage.removeItem("persist:root");
        Cookies.remove("userData");
        Cookies.set("apiToken", data.token);
        dispatch(getUserInfo())
    }

    useEffect(() => {
        if (getUserInfoResponse != null) {
            if (Object.keys(getUserInfoResponse).length != 0 && getUserInfoResponse.statusCode != 200) {
                toast.error(getUserInfoResponse.message)
                dispatch(clearGetUserInfo())
            }
            if (Object.keys(getUserInfoResponse).length != 0 && getUserInfoResponse.statusCode == 200) {
                Cookies.set("userData", JSON.stringify({
                    email: getUserInfoResponse.data[0].email,
                    company_name: getUserInfoResponse.data[0].company_name,
                    image: getUserInfoResponse.data[0].image
                }));
                navigate("/dashboard");
            }
        }
    }, [getUserInfoResponse])

    return (
        <section className="mid-wrap pt-3 pb-5">
            <div className="mid-card-block">
                <div className="mxw-750">

                    <h5 className="card-title lg-title mb-4 fw-700">
                        Sign In To Continue
                    </h5>
                    <div className="card form-card">
                        <div className="card-body">
                            <Formik
                                initialValues={{
                                    email:
                                        localStorage.getItem("userEmail") != null
                                            ? localStorage.getItem("userEmail")
                                            : "",
                                    pin: "",
                                    device_id: "web",
                                    device_token: "web",
                                    device_type: "web"
                                }}
                                validationSchema={LoginValidationSchema}
                                onSubmit={(values) =>
                                    loginAPI(values)
                                }
                            >
                                {({ errors, touched }) => (
                                    <Form className="custom-form">
                                        {/* Email */}
                                        <div className="form-group">
                                            <label>Email Address</label>

                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="email"
                                                autoComplete="off"
                                            />

                                            {errors.email && touched.email && (
                                                <small className="text-danger">{errors.email}</small>
                                            )}
                                        </div>

                                        {/* Pin */}
                                        <div className="form-group">
                                            <label>PIN</label>

                                            <Field
                                                type="password"
                                                className="form-control"
                                                name="pin"
                                                autoComplete="off"
                                            />

                                            {errors.pin && touched.pin && (
                                                <small className="text-danger">{errors.pin}</small>
                                            )}

                                            {errorMsg && !errors.pin && !errors.email && (
                                                <small className="text-danger">{errorMsg}</small>
                                            )}
                                        </div>
                                        <div className="form-group text-end">
                                            <Link
                                                to="/forgot-pin"
                                                className="blank-link text-decoration-underline"
                                            >
                                                Forget/Generate Pin
                                            </Link>
                                        </div>

                                        {loginLoading || getUserInfoLoading ? (
                                            <AuthBtnSpinner />
                                        ) : (
                                            <button className="theme-btn btn w-100" type="submit">
                                                Login
                                            </button>
                                        )}

                                        {
                                            <div className="sm-title mt-4">
                                                Don't have an account ?
                                                <div className="mt-3">
                                                    <Link to="/register">
                                                        <u>Register a Startup</u>
                                                    </Link>
                                                </div>
                                            </div>
                                        }
                                    </Form>
                                )}
                            </Formik>
                            <div className="text-center mt-30">
                                <Link
                                    to={process.env.REACT_APP_TERMS_OF_USE_URL}
                                    target="_blank"
                                    className="white-btn w-360"
                                >
                                    Terms of use
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;