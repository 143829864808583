import React from 'react';

const SelectedStartupView = ({ dealDetailData }) => {
    return (
        <>
            <div className='row'>
                <div className='col-12 mb-3'>
                    <div className='custom-card cc-new-theme card'>
                        <div className='card-header'>
                            Startup Information
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className="col-md-4 col-6 mt-2 mb-3">
                                    <div>
                                        <div className="v-label">Syndicates</div>
                                        <div className="v-value">

                                            {dealDetailData?.syndicates.length > 0 ? dealDetailData?.syndicates?.map((syndicate, index) => (
                                                <React.Fragment key={index}>
                                                    {syndicate?.syndicate_name}
                                                    {index !== dealDetailData.syndicates.length - 1 && ", "}
                                                </React.Fragment>
                                            )) :
                                                "-"
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 mt-2 mb-3">
                                    <div>
                                        <div className="v-label">Startup Name</div>
                                        <div className="v-value">{dealDetailData?.company_name ? dealDetailData?.company_name : "-"}</div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 mt-2 mb-3">
                                    <div>
                                        <div className="v-label">Entity Name</div>
                                        <div className="v-value">{dealDetailData?.entity_name ? dealDetailData?.entity_name : "-"}</div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 mt-2 mb-3">
                                    <div>
                                        <div className="v-label">Email</div>
                                        <div className="v-value">{dealDetailData?.email ? dealDetailData?.email : "-"}</div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 mt-2 mb-3">
                                    <div>
                                        <div className="v-label">Mobile Number</div>
                                        <div className="v-value">{dealDetailData?.phone_code === "-" ? "-" : dealDetailData?.phone_code}{dealDetailData?.mobile_number}</div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 mt-2 mb-3">
                                    <div>
                                        <div className="v-label">City</div>
                                        <div className="v-value">{dealDetailData?.city_name ? dealDetailData?.city_name : "-"}</div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-6 mt-2 mb-3">
                                    <div>
                                        <div className="v-label">Formation Date</div>
                                        <div className="v-value">{dealDetailData?.createdAt ? dealDetailData?.createdAt : "-"}</div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 mt-2 mb-3">
                                    <div>
                                        <div className="v-label">Stage</div>
                                        <div className="v-value">{dealDetailData?.stage[0]?.value}</div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 mt-2 mb-3">
                                    <div>
                                        <div className="v-label">Sector</div>
                                        <div className="v-value">{dealDetailData?.sector?.value ? dealDetailData?.sector?.value : "-"}</div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 mt-2 mb-3">
                                    <div>
                                        <div className="v-label">Tags</div>
                                        <div className="v-value">{dealDetailData?.tags ? dealDetailData?.tags?.map(item => item?.name)?.join(',') : "-"}</div>
                                    </div>
                                </div>
                                <div className="col-12 mt-2 mb-3">
                                    <div className='d-block align-items-center'>
                                        <div className="v-label">Founders</div>
                                        {dealDetailData?.founders?.length > 0 ?
                                            dealDetailData?.founders?.map((founder, index) => (
                                                <div className="d-flex align-items-center mb-3" key={index}>
                                                    <div className="pe-3">
                                                        <a href={founder?.linkedln_profile_url} target="_blank" rel="noopener noreferrer" className="v-value value-link">{founder?.name}</a>
                                                    </div>
                                                </div>
                                            )) : <div className='v-value'>-</div>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    <div className='custom-card cc-new-theme card mb-3'>
                        <div className='card-header'>
                            About
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className="col-12 mt-2 mb-3">
                                    <div className='d-block align-items-center'>
                                        <div className="v-label">Description</div>
                                        <div className="v-value pre-wrap">{dealDetailData?.description ? dealDetailData?.description : "-"}</div>
                                    </div>
                                </div>
                                <div className="col-12 mt-2 mb-1">
                                    <div className='d-block align-items-center'>
                                        <div className="v-label">Overview</div>
                                        <div className="v-value pre-wrap">{dealDetailData?.overview ? dealDetailData?.overview : "-"}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {dealDetailData?.startup_details?.length > 0 &&
            <div className='custom-card cc-new-theme card mb-3'>
                <div className='card-header'>
                    Startup Internal Details
                </div>
                <div className='card-body'>

                    <div className='row'>
                        {dealDetailData?.startup_details?.map((item, index) => (
                            <>
                                <div className="col-12 mt-2 mb-3">
                                    <div className='d-block'>
                                        <div className="v-label">{item.key}</div>
                                        <div className="v-value pre-wrap">{item.value ? item.value : "-"}</div>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </div>
            }
        </>
    )
}
export default SelectedStartupView;