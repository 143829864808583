import React, { useEffect, useState } from "react";
import { MainLogo } from "../../assets/img";
function Information({setStep}) {
    return (
     <section className="mid-wrap pt-5 pb-5">
        <div className="mid-card-block">
          <div className="mxw-520">
            {/* <div className="text-center mb-4 vendor-info">
              <img src={logoVr} alt="my mandi" />
            </div> */}

            <h5 className="card-title lg-title mb-4 fw-700">RTAF Startup Application</h5>
            <div className="card form-card">
              <div className="card-body">
                {/* <h5 className="card-title lg-title">OTP Verification</h5> */}
                {/* <div className="mb-4 text-center mt-3">
                  <img style={{maxWidth: "200px"}} src={MainLogo} alt="logo"/>
                </div> */}
                <p className="sm-title font-1em mb-5 mt-0">
                  Fill this form if you are one of the founders / co-founders of the startup.
                </p>
                <button className="theme-btn btn w-100" onClick={()=> setStep(2)} type="button">
                      Start
                </button>
              </div>
            </div>
          </div>
        </div>
    </section>
    );
  }


  export default Information;
