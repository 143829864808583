import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import { clearGetUserInfo, getUserInfo } from '../../store/actions/getUserInfoAction'
import Modal from '../../components/Modal'
import Loading from '../../components/Loading'
import Clickable from '../../utils/Clickable'
import { Tooltip } from '@mui/material'
import { DescriptionOutlined, Facebook, Instagram, Language, LinkedIn, Twitter, VideoCameraBack } from '@mui/icons-material'
import ModeIcon from '@mui/icons-material/Mode';
import { useNavigate } from 'react-router-dom'
import SelectedStartupView from './SelectedStartupView'
import StartupDocumentPopup from './StartupDocumentPopup'
import defaultStartupIcon from '../../assets/img/business.png'

export default function Application() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [startupDetail, setStartupDetail] = useState(null)
    const [documentOpen, setDocumentOpen] = useState(false);

    const handleDocumentOpen = () => {
        setDocumentOpen(true);
    };
    const documentHandleClose = () => setDocumentOpen(false);

    const getUserInfoResponse = useSelector(state => state.getUserInfoReducer.data)
    const getUserInfoLoading = useSelector(state => state.getUserInfoReducer.loading)

    useEffect(() => {
        dispatch(getUserInfo())
    }, [])

    useEffect(() => {
        if (getUserInfoResponse != null) {
            if (Object.keys(getUserInfoResponse).length != 0 && getUserInfoResponse.statusCode != 200) {
                toast.error(getUserInfoResponse.message)
                dispatch(clearGetUserInfo())
            }
            if (Object.keys(getUserInfoResponse).length != 0 && getUserInfoResponse.statusCode == 200) {
                setStartupDetail(getUserInfoResponse.data[0])
            }
        }
    }, [getUserInfoResponse])

    return (
        <>
            <Modal open={documentOpen} onClose={documentHandleClose} closeIcon={true} style={{ width: '600px' }} disableScrollLock={true}>
                <StartupDocumentPopup data={startupDetail?.startup_documents} />
            </Modal>
            {/* <ToastContainer /> */}
            {
                getUserInfoLoading ? (
                    <Loading />
                ) : (
                    <div className="custom-menu-block view-investor-block">
                        <div className="deal-head-flex d-flex align-items-center mb-4">
                            <Clickable
                                className="circle-box"
                            >
                                <img src={startupDetail?.image ? startupDetail?.image : defaultStartupIcon} alt="" />
                            </Clickable>
                            <div className="dhf-right bottom-border-height">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Clickable
                                            className="xs-title font-16"
                                        >
                                            {startupDetail?.company_name}
                                        </Clickable>
                                        <div>
                                            <div className="d-flex align-items-center">

                                                {startupDetail?.linkedln_page_url &&
                                                    <a href={startupDetail?.linkedln_page_url} target="_blank" rel="noopener noreferrer"
                                                        className="text-decoration-none icon text-center me-3"
                                                    >
                                                        <Tooltip title="LinkedIn">
                                                            <LinkedIn />
                                                        </Tooltip>
                                                    </a>
                                                }
                                                {startupDetail?.twitter_page_url &&
                                                    <a href={startupDetail?.twitter_page_url} target="_blank" rel="noopener noreferrer"
                                                        className="text-decoration-none icon text-center me-3"
                                                    >
                                                        <Tooltip title="Twitter">
                                                            <Twitter />
                                                        </Tooltip>
                                                    </a>
                                                }
                                                {startupDetail?.facebook_page_url &&
                                                    <a href={startupDetail?.facebook_page_url} target="_blank" rel="noopener noreferrer"
                                                        className="text-decoration-none icon text-center me-3"
                                                    >
                                                        <Tooltip title="Facebook">
                                                            <Facebook />
                                                        </Tooltip>
                                                    </a>
                                                }
                                                {startupDetail?.instagram_page_url &&
                                                    <a href={startupDetail?.instagram_page_url} target="_blank" rel="noopener noreferrer"
                                                        className="text-decoration-none icon text-center me-3"
                                                    >
                                                        <Tooltip title="Instagram">
                                                            <Instagram />
                                                        </Tooltip>
                                                    </a>
                                                }
                                                {startupDetail?.videos.length > 0 ?
                                                    startupDetail?.videos?.map((videos) => (
                                                        <a href={videos?.url} target="_blank" rel="noopener noreferrer"
                                                            className="text-decoration-none icon text-center me-3"
                                                        >
                                                            <Tooltip title={videos?.heading}>
                                                                <VideoCameraBack />
                                                            </Tooltip>
                                                        </a>
                                                    )
                                                    ) : ""}
                                                <Clickable
                                                    onClick={() => window.open(startupDetail?.website_url, 'blank')}
                                                    className="text-decoration-none icon text-center me-3"
                                                >
                                                    <Tooltip title="Website">
                                                        <Language />
                                                    </Tooltip>
                                                </Clickable>
                                                {startupDetail?.startup_documents?.length > 0 && <Tooltip title="Documents">
                                                    <button
                                                        type="button"
                                                        className="text-decoration-none  icon text-center me-3"
                                                        onClick={handleDocumentOpen}
                                                    >
                                                        <DescriptionOutlined />
                                                    </button>
                                                </Tooltip>}
                                                {/* <Tooltip title="Edit">
                                                <div className="cursor-pointer">
                                                    <ModeIcon sx={{ color: '#777' }} onClick={() => navigate(`/startups/editStartup/${startupDetail?._id}`)} />
                                                </div>
                                            </Tooltip> */}
                                            </div>
                                        </div>
                                    </div>
                                {/* <div>
                                    <ul className="nav nav-tabs custom-nav-tabs dh-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={'nav-link active'}
                                                // onClick={() => navigate(`/startups/view-startup/${id}`)}
                                                id="DealDetails-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#DealDetails"
                                                type="button"
                                                role="tab"
                                                aria-controls="DealDetails"
                                                aria-selected="false"
                                            >
                                                Startup Details
                                            </button>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>

                        <div className="tab-content" id="myTabContent">
                            <div
                                className={'tab-pane fade show active'}
                                id="DealDetails"
                                role="tabpanel"
                                aria-labelledby="DealDetails-tab"
                            >
                                <SelectedStartupView dealDetailData={startupDetail} />
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}
