import { useEffect, useState } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './Nav/NavGroup';
import Cookies from 'js-cookie';
import { IconBuildingSkyscraper, IconCategory, IconGraph, IconPhone ,IconReport} from '@tabler/icons';
import { type } from 'jquery';
// ==============================|| SIDEBAR MENU LIST ||============================== //


const MenuList = () => {
    const [menuItems, setmenuItems] = useState([])

    useEffect(() => {
        const dashboard = {
            id: 'dashboard',
            title: '',
            type: 'group',
            children: [
                {
                    id: 'dashboard1',
                    title: 'Dashboard',
                    type: 'item',
                    url: ``,
                    icon: IconCategory,
                    breadcrumbs: false
                }
            ]
        }
        const fundRaise = {
            id: 'fundRaise',
            title: '',
            type: 'group',
            children: [
                {
                    id: 'fundRaise1',
                    title: 'Fund Raise',
                    type: 'collapse',
                    icon: IconBuildingSkyscraper,
                    breadcrumbs: false,
                    children: [
                        {
                            id: 'application',
                            title: 'Application',
                            type: 'item',
                            url: `/fund-raise/application`,
                            icon: IconBuildingSkyscraper,
                            breadcrumbs: false
                        }
                    ]
                }
            ]
        }
        const report ={
            id: 'report',
            title: '',
            type: 'group',
            children: [
            {
                id: 'report1',
                title: 'Reports',
                type: 'collapse',
                icon: IconReport,
                breadcrumbs: false,
                children: [
                {
                    id: 'reportListing',
                    title: 'View Reports',
                    type: 'item',
                    url: `/reports`,
                    icon: IconReport,
                    breadcrumbs: false
            },
                {
                    id: 'Report1222',
                    title: 'Upload Report',
                    type: 'item',
                    url: `/reports/create-report`,
                    icon: IconReport,
                    breadcrumbs: false
                },
                

                ],
                
            }
            ]
        }
        const checklist = {
            id: 'checklist',
            title: '',
            type: 'group',
            children: [{
                id: 'checklist1',
                title: 'Checklist',
                type: 'item',
                url: `/checklist`,
                icon: IconGraph,
                breadcrumbs: false
            }]
        };
        const contactUs = {
            id: 'contactUs',
            title: '',
            type: 'group',
            children: [{
                id: 'contactUs1',
                title: 'Contact Us',
                type: 'item',
                url: `/contact-us`,
                icon: IconPhone,
                breadcrumbs: false
            }]
        };
        // const Report = {
        //     id: 'Report',
        //     title: '',
        //     type: 'group',
        //     children: [{
        //         id: 'Report1222',
        //         title: 'Create Report',
        //         type: 'item',
        //         url: `/report`,
        //         icon: IconReport,
        //         breadcrumbs: false
        //     }]
        // };
        // const ReportListing = {
        //     id: 'ReportListing',
        //     title: '',
        //     type: 'group',
        //     children: [{
        //         id: 'ReportListing1',
        //         title: 'Report Listing',
        //         type: 'item',
        //         url: `/reports`,
        //         icon: IconReport,
        //         breadcrumbs: false
        //     }]
        // };

    const array_items = [fundRaise, report,contactUs];
    setmenuItems(array_items);
}, [Cookies]);
    const navItems = menuItems.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;