import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import crossImg from "../../assets/img/cross-icon.svg";
import pdfIcon from "../../assets/img/pdfn.png";
// import { DeleteDocuments } from "../../api";
import Select from "react-select";

export const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  type = "text",
  ...props
}) => (
  <div >
    <input type={type} class="form-control" {...field} {...props} />
    {touched[field.name] && errors[field.name] && (
      <small className="text-danger">{errors[field.name]}</small>
    )}
  </div>
);

export const CustomTextareaComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  type = "text",
  ...props
}) => (
  <div>
    <textarea type={type} class="form-control" {...field} {...props} />
    {touched[field.name] && errors[field.name] && (
      <small className="text-danger">{errors[field.name]}</small>
    )}
  </div>
);

export const CustomSelectComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  options = [],
  excludeIndia = "No",
  initialOption = "Select an option",
  ...props
}) => (
  <div>
    <select className="form-control" {...field} {...props}>
      <option value="">{initialOption}</option>
      {options.map((option) =>
        excludeIndia == "Yes" ? (
          option.value != "101" && (
            <option key={option.value} value={option.value} slug={option.value}>
              {option.label}
            </option>
          )
        ) : (
          <option key={option.value} value={option.value} slug={option.value}>
            {option.label}
          </option>
        )
      )}
    </select>
    {touched[field.name] && errors[field.name] && (
      <small className="text-danger">{errors[field.name]}</small>
    )}
  </div>
);

export const CustomRadioComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  options = [],
  onChange, // added onChange prop
  ...props
}) => {
  const handleRadioChange = (event) => {
    if (onChange) {
      onChange(event);
    }
    setFieldValue(field.name, event.target.value);
  };

  return (
    <div>
      {options.map((option) => (
        <div key={option._id} class="react-radio radio-inline mr-15">
          <label class="form-check-label">
            <input
              type="radio"
              class="form-check-input"
              {...field}
              {...props}
              checked={field.value === option._id}
              value={option._id}
              onChange={handleRadioChange} // pass event to handler
            />
            <span>{option.value}</span>
          </label>
        </div>
      ))}
      {touched[field.name] && errors[field.name] && (
        <div class="text-danger">{errors[field.name]}</div>
      )}
    </div>
  );
};

export const CustomRadioRefrealComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  options = [],
  onChange, // added onChange prop
  ...props
}) => {
  const handleRadioChange = (event) => {
    if (onChange) {
      onChange(event);
    }
    setFieldValue(field.name, event.target.value);
  };

  return (
    <div>
      {options.map((option) => (
        <div key={option._id} class="react-radio radio-inline mr-15">
          <label class="form-check-label">
            <input
              type="radio"
              class="form-check-input"
              {...field}
              {...props}
              checked={field.value === option.value}
              value={option.value}
              onChange={handleRadioChange} // pass event to handler
            />
            <span>{option.value}</span>
          </label>
        </div>
      ))}
      {touched[field.name] && errors[field.name] && (
        <div class="text-danger">{errors[field.name]}</div>
      )}
    </div>
  );
};

export const CustomCheckbox = ({
  field,
  form: { touched, errors },
  label,
  onChange,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();

  // const handleChange = (evt) => {
  //   const { value } = evt.target;
  //   setFieldValue(field.name, value);
  // };

  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    }

    field.onChange({
      target: {
        name: field.name,
        value: !field.value,
      },
    });
  };

  return (
    <div className="react-checkbox">
      <label>
        <input
          type="checkbox"
          {...field}
          {...props}
          onChange={handleChange}
          checked={Boolean(field.value)}
        />
        <span>{label}</span>
      </label>
      {touched[field.name] && errors[field.name] && (
        <small className="text-danger">{errors[field.name]}</small>
      )}
    </div>
  );
};

export const CustomCheckboxGroup = ({
  options,
  field,
  multiline,
  form: { touched, errors },
  ...props
}) => {
  return (
    <>
      <div className={multiline == "yes" ? "multiline" : "singleline"}>
        {options.map((option, index) => (
          <div className="react-checkbox" key={option.value}>
            <label>
              <input
                type="checkbox"
                id={`${field.name}-${index}`}
                name={field.name}
                value={option.value}
                checked={field.value.includes(option.value)}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  const newValue = [...field.value];
                  if (isChecked) {
                    newValue.push(option.value);
                  } else {
                    const index = newValue.indexOf(option.value);
                    if (index !== -1) {
                      newValue.splice(index, 1);
                    }
                  }
                  field.onChange({
                    target: { name: field.name, value: newValue },
                  });
                }}
                {...props}
              />
              <span>{option.label}</span>
            </label>
          </div>
        ))}
      </div>
      {touched[field.name] && errors[field.name] && (
        <small className="text-danger">{errors[field.name]}</small>
      )}
    </>
  );
};

export const FileInputComponent = ({
  field,
  form: { touched, errors },
  label,
  checkFileExixtsORNot,
  ...props
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { setFieldValue } = useFormikContext();
  // const { mutateAsync: deleteDocs } = DeleteDocuments();
  useEffect(() => {
    if (field.value?.map) {
      setSelectedFiles(() => field.value.map((ob) => ob));
    }
  }, [field.value ? field.value : []]);

  const handleFileChange = (event) => {
    let files = event.target.files;
    // files[0]["blob"] = URL.createObjectURL(files[0])
    setFieldValue(field.name, [...files]);
    // setSelectedFiles([...field.value, ...files])
    setSelectedFiles((oldFiles) => {
      // let arr = [];
      for (let i = 0; i < files.length; i++) {
        // let file = files[i];
        files[i]["blob"] = URL.createObjectURL(files[i]);
        // if (file) {
        //   arr = [...arr, URL.createObjectURL(file)];
        // }
      }
      return [...files];
    });
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*,application/pdf"
        onChange={handleFileChange}
        multiple={false}
      />
      {/* <div className="xs-title text-start mt-1">
        *Upload self attested document
      </div> */}
      <>
        {selectedFiles.map((selectedFile, index) => (
          <div
            className={
              selectedFile?.type &&
              (selectedFile.type == "pdf" ||
                selectedFile.type == "application/pdf")
                ? "uploaded-imgs-block w-100 typepdf"
                : "uploaded-imgs-block w-100"
            }
            key={index}
          >
            <div className="uploaded-box position-relative">
              {selectedFile?.type &&
              (selectedFile.type == "pdf" ||
                selectedFile.type == "application/pdf") ? (
                <a
                  className="pdficon"
                  href={
                    selectedFile?.url ? selectedFile?.url : selectedFile?.blob
                  }
                  target="_blank"
                >
                  <img src={pdfIcon} alt="pdf" />{" "}
                  <span className="pdfname">
                    {selectedFile?.filename
                      ? selectedFile?.filename
                      : selectedFile.name}
                  </span>
                </a>
              ) : (
                <img
                  src={
                    selectedFile?.url ? selectedFile?.url : selectedFile?.blob
                  }
                  alt="Uploaded"
                />
              )}
              <div
                className="cross-icon"
                onClick={() => {
                  let newFiles = selectedFiles.filter((_, i) => i != index);
                  setSelectedFiles(newFiles);
                  selectedFile.document_id &&
                    // deleteDocs({ payload: selectedFile.document_id });
                  setFieldValue(field.name, newFiles);

                  if (checkFileExixtsORNot) {
                    checkFileExixtsORNot(newFiles);
                  }
                }}
              >
                <img src={crossImg} alt="cross" />
              </div>
            </div>
          </div>
        ))}
      </>
      {touched[field.name] && errors[field.name] && (
        <small className="text-danger">{errors[field.name]}</small>
      )}
    </div>
  );
};

export const CustomMultDropDownComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  options = [],
  initialOption = "Select an option",
  ...props
}) => {
  const [selectedOption, setSelectedOption] = useState([]);

  // if(field.value && (field.value.length)>0){
  //    setSelectedOption(field.value);
  //    setFieldValue(field.name, field.value);
  // }

  useEffect(() => {
    if (field.value?.map) {
      var newexpertiseInSectors = [];
      field.value.map((ob) =>
        newexpertiseInSectors.push({
          value: ob?.value ? ob?.value : ob,
          label: ob?.value ? ob?.value : ob,
        })
      );
      setSelectedOption(newexpertiseInSectors);
      //setFieldValue(field.name, newexpertiseInSectors);
    }
  }, [field.value ? field.value : []]);

  const handleChange = (selected) => {
    setSelectedOption(selected);
    setFieldValue(field.name, selected);
  };

  return (
    <div className="App">
      <Select
        onChange={handleChange}
        options={options}
        isMulti={true}
        isSearchable={true}
        placeholder={initialOption}
        name={field.name}
        value={selectedOption}
        // defaultInputValue ={selectedOption}
      />

      {touched[field.name] && errors[field.name] && (
        <small className="text-danger">{errors[field.name]}</small>
      )}
    </div>
  );
};
