import {types} from './types';

export function forgotPIN(data) {
  return {
    type: types.SEND_REQUEST_FORGOT_PIN,
    payload: data,
  };
}
export function forgotPINSuccess(data) {
  return {
    type: types.SEND_REQUEST_FORGOT_PIN_SUCCESS,
    payload: data,
  };
}

export function forgotPINFailure(error) {
  return {
    type: types.SEND_REQUEST_FORGOT_PIN_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearForgotPIN() {
  return {
    type: types.CLEAR_REQUEST_FORGOT_PIN,
    payload: {},
  };
}