import React, { useState, useEffect } from "react";
import { Field, Form, Formik, FastField } from "formik";
import * as Yup from "yup";
import BtnSpinner from "../../utils/BtnSpinner";
import {
  CustomInputComponent,
  CustomSelectComponent,
  CustomRadioComponent,
  CustomCheckbox,
  CustomTextareaComponent,
  FileInputComponent,
  CustomRadioRefrealComponent,
} from "../../components/formik/FieldComponents";
import { Link, useNavigate } from "react-router-dom";

// import { useDispatch, useSelector } from "react-redux";
// import {
//   UPDATE_PERSONALISED_INFORMATION,
//   SET_PAGE,
// } from "../../../../store/actions";
import { GetSectors,StartupForm } from "../../api";
import { Back } from "../../assets/img";

 const personalInformation = {
      referral_name: "",
      referral_through:"",
  };

function ReferalDetails({setStep}) {
  const navigate = useNavigate();
  const { data: Secotrs, isLoading: isContriesLoading } =    GetSectors();
  const [isHowHearAbout, setHowHearAbout] = React.useState(false);


  const [ischeckValidate, setcheckValidate] = React.useState(true);
  const [ischeckValidatefileAddressProof, setcheckValidatefileAddressProof] =
    React.useState(true);

    const {
      mutateAsync: startUpData,
      status: otpStatus,
      error,
      isLoading,
    } = StartupForm();

     const moreAboutYouData = {
        haveInvestedBefore: [
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
        ],
      
        howHearAboutUs: [
          { label: "Friends", value: "Friends" },
          {
            label: "Incubator",
            value: "Incubator",
          },
          { label: "Linkedin Search", value: "Linkedin Search" },
          {
            label: "Investor",
            value: "Investor",
          },
        ],
      };

      const handleRadioChange = (e) => {
        var checkValue = e.target.value;
        if (checkValue != "search" && checkValue != "news") {
          setHowHearAbout(true);
        } else {
          setHowHearAbout(false);
        }
      };
    

//   useEffect(() => {
//     if (
//       !isDocsLoading &&
//       docs.fileIdentityProof &&
//       docs.fileIdentityProof.length > 0
//     ) {
//       setcheckValidate(false);
//     } else {
//       setcheckValidate(true);
//     }

//     if (
//       !isDocsLoading &&
//       docs.fileAddressProof &&
//       docs.fileAddressProof.length > 0
//     ) {
//       setcheckValidatefileAddressProof(false);
//     } else {
//       setcheckValidatefileAddressProof(true);
//     }
//   }, [isDocsLoading, docs]);

    // console.log(Secotrs,"banksbanksbanksbanksbanksbanks")
  return (
    <section className="mid-wrap">
      <div className="forms-header pt-3 pb-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-2 col-2">
              <Link   className="back-arrow">
                <img   onClick={()=> setStep(5)} src={Back} alt="back" />
              </Link>
            </div>
            <div className="col-md-8 col-8">
              <div className="lg-title text-center">Referral Details</div>
            </div>
            {/* <div className="col-md-2 col-2 text-end">
              <span className="pl-15 sm-title">4/4</span>
            </div> */}
          </div>
          <div className="text-center step-process mt-1">
            <div className="d-inline-flex">
              <span className="process-step active"></span>
              <span className="process-step active"></span>
              <span className="process-step active"></span>
              <span className="process-step selected"></span>
             
            </div>
          </div>
          {/* <h5 className="card-title md-title mt-2 mb-0 text-center heading-formobile">
            Personal Information
          </h5> */}
        </div>
      </div>
      <div className="pt-0 pb-5 kyc-form-block">
        <div className="container">
          <div className="card form-card mt-4">
            <div className="card-body">
              {/* <h5 className="card-title md-title mb-4 text-center heading-fordesktop">
                Personal Information
              </h5> */}
              <Formik
                initialValues={personalInformation}
                // validateOnChange={false}
                // validateOnBlur={true}
                enableReinitialize={true}
                // validationSchema={PersonalInformationValidationSchema}
                onSubmit={(values) => {
                  const storedUserObject = localStorage.getItem('user_details');
                  const UserObject =  JSON.parse(storedUserObject)
                  localStorage.setItem('user_name',UserObject.firstName+ " "+UserObject.lastName);
                  const data =  {"email" :UserObject.email,...values,is_form_submitted:"Yes"};
                  startUpData(data)
                  localStorage.removeItem('user_details');
                  localStorage.removeItem('startup_details');
                  localStorage.removeItem('startup_id');
                  setStep(7)
                }}
              >
                {({ errors, touched, values }) => (
                  <Form className="custom-form">
                    <div className="row">
                      {/* First Name */}
                      <div className="col-12 form-group">
                          How did you hear about us?
                        <div className="rr-col-2">
                          <Field
                            name="referral_through"
                            component={CustomRadioRefrealComponent}
                            options={moreAboutYouData.howHearAboutUs}
                            onChange={handleRadioChange}
                          />
                        </div>
                      </div>

                      {!isContriesLoading && 
                        <div className="col-12 form-group">
                        Referrer Name
                        <Field
                          name="referral_name"
                          component={CustomInputComponent}
                          type="text"
                        />
                      </div>}

                   

                      {/* Email Address */}
                      {/* <div className="col-md-6 form-group">
                        <label>Email Address</label>
                        <Field
                          name="emailAddress"
                          component={CustomInputComponent}
                          type="email"
                        />
                      </div> */}

                  

              

                      {/* Occupation */}
                   

                  
                      {/* Country of citizenship */}
                      {/* <div className="col-md-6 form-group">
                        <label className="required">
                          Select Individual Type
                        </label>
                        <Field
                          name="individual_type"
                          component={CustomSelectComponent}
                          options={IndianCitizenUserType.individual_type}
                        />
                      </div> */}

                      {/* NRI Checkbox */}
                      {/* <div className="col-md-12 form-group">
                        <div className="react-checkbox">
                          <Field
                            name="nriCheckbox"
                            component={CustomCheckbox}
                            label="Are you a Non-Resident Indian (NRI)?"
                          />
                        </div>
                      </div> */}
                    </div>
                    {/* {isSavePersonalInformationLoading ? (
                      <BtnSpinner />
                    ) : ( */}
                      <div className="mt-3 text-center">
                        <button type="submit"  className="theme-btn btn w-25">
                          Submit
                        </button>
                      </div>
                    {/* )} */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          {/* <div className="text-center mt-30">
            <a href="#" className="white-btn w-360">
              Terms of Use
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default ReferalDetails;
