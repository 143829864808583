import React, { useState } from 'react';
import { FetchReportListing, MSIDownloadDocument ,FetchReportDetails} from '../../../api';
import Card from '../../../components/Card';
import Loading from '../../../components/Loading';
import AsyncTableListing from '../../../components/AsyncTableListing';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import Clickable from '../../../utils/Clickable';
import { IconEdit } from '@tabler/icons';
import { DescriptionOutlined } from '@mui/icons-material';
import StartupDocumentPopup from '../StartupDocumentPopup';
import ModalComponent from '../../../components/Modal';
import PrivateDocumentPopup from './PrivateDocumentPopup';

const columns = [
  // {
  //   label: 'Name',
  //   minWidth: 200,
  //   align: 'center',
  //   type: 'name'
  // },
  {
    label: 'Report Name',
    minWidth: 300,
    align: 'center',
    type: 'title'
  },
  {
    label: 'From Date',
    minWidth: 200,
    align: 'center',
    type: 'from_date_format'
  },
  {
    label: 'To Date',
    minWidth: 200,
    align: 'center',
    type: 'to_date_format'
  },
  {
    label: 'Created On',
    minWidth: 300,
    align: 'center',
    type: 'uploaded_date_format'
  },
  {
    label: 'Actions',
    minWidth: 60,
    align: 'center',
    type: 'actions'
  },
];

const ReportListing = () => {
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState(-1);
  const [limit, setLimit] = useState(20);
  const { isLoading: isReportListingLoading, data: ReportListingData } = FetchReportListing();
  const { mutateAsync: downloadDocument } = MSIDownloadDocument();
  const [documentOpen, setDocumentOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  
  const handleDocumentOpen = (row) => {
    setCurrentRow(row);
    setDocumentOpen(true);
  };
  
  const documentHandleClose = () => setDocumentOpen(false);
  
  const ReportAction = ({ row }) => {
    const navigate = useNavigate();
    return (
      <div style={{ display: 'flex', justifyContent: 'left', columnGap: "6px" }}>
        <Clickable style={{ display: 'flex', alignItems: 'center' }} onClick={() => navigate(`/reports/create-report/${row._id}`)}>
          <Tooltip title="Edit">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconEdit stroke={1.5} size="1.4rem" sx={{ width: '1.4rem !important', height: '1.4rem !important' }} />
            </div>
          </Tooltip>
        </Clickable>
        {row.documents && row.documents.length > 0 &&
        <Clickable style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleDocumentOpen(row)}>
          <Tooltip title="Documents">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DescriptionOutlined />
            </div>
          </Tooltip>
        </Clickable>
        }
      </div>
    );
  };
  
  // const transformedData = ReportListingData?.map((row) => ({
  //   ...row,
  //   upload_type_format: row.upload_type === 'balance_sheet' ? 'Balance Sheet' : row.upload_type === 'p&l' ? 'P&L' : 'Other',
  // }));
  
  return (
    <>
      <ModalComponent open={documentOpen} onClose={documentHandleClose} closeIcon={true} style={{ width: '600px' }} disableScrollLock={true}>
        <PrivateDocumentPopup data={currentRow?.documents} />
      </ModalComponent>
      <Card style={{ paddingLeft: '16px', paddingRight: '16px' }} title={"Reports"} backTo={"/dashboard"}>
        <div className='card-body'>
          <div className='custom-table'>
            {isReportListingLoading ? <Loading />
              : <AsyncTableListing
                title=""
                tableStyle={{minWidth: 700}}
                columns={columns}
                data={ReportListingData}
                actions={ReportAction}
                page={page}
                setPage={setPage}
                count={ReportListingData}
                sort={sort}
                setSort={setSort}
                limit={limit}
                setLimit={setLimit}
                pagination={false}
              />}
          </div>
        </div>
      </Card>
    </>
  );
};

export default ReportListing;
