import { combineReducers } from "redux";
import loginReducer from "./loginReducers";
import getUserInfoReducer from "./getUserInfoReducer";
import forgotPINReducer from "./forgotPINReducer";
import saveProfileImageReducer from "./saveProfileImageReducer";
import setMenuReducer from "./setMenuReducer";
import menuOpenReducer from "./menuOpenReducer";
import setBorderRadiusReducer from "./setBorderRadiusReducer";
import setFontFamilyReducer from "./setFontFamilyReducer";
import customizationReducer from "./customization";
import contactUsReducer from "./contactUsReducer";
import changePINReducer from "./changePINReducer";

export default combineReducers({
    loginReducer,
    getUserInfoReducer,
    forgotPINReducer,
    saveProfileImageReducer,
    setMenuReducer,
    menuOpenReducer,
    setBorderRadiusReducer,
    setFontFamilyReducer,
    customization: customizationReducer,
    contactUsReducer,
    changePINReducer,
});