import moment from "moment";
// import { RealtimeSVG } from "../../assets/img";
import { Backrr } from "../../assets/img";

const Footer = () => {
  return (
    <footer className="footer-wrap">
      <div className="container">
        &copy; {moment().format("Y")} {process.env.REACT_APP_APPLICATION_NAME}. All Rights Reserved. &nbsp;
        <img className="realtime-logo" src={Backrr} alt={process.env.REACT_APP_APPLICATION_NAME} />
      </div>
    </footer>
  );
};

export default Footer;
