import React, { useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, Box, Pagination, Typography, Checkbox, Stack, Avatar, Hidden, TableCell } from '@mui/material';
// import { TableCell, TableCell } from 'ui-component/TableStyles';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate } from 'react-router-dom';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
// import Clickable from 'utils/Clickable';
import { Tooltip } from '@mui/material';
// import { DownloadStampFile } from 'api';
import { convertToIndianCurrencyWords } from './conversionUtils.js';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles({
    customTable: {
        '& td.MuiTableCell-root': { padding: '10px 16px 10px 16px' },
        '& .MuiTableCell-head': {
            color: 'white',
            backgroundColor: '#0000FF'
        }
    }
});

const stringAvatar = (name) => {
    let currentName = name?.trim()
    if (currentName !== '' && currentName !== undefined) {
        let avatarname0 = currentName?.trim()?.toUpperCase()?.split(' ')[0] !== undefined ? currentName?.toUpperCase()?.split(' ')[0][0] : '';
        let avatarname1 = currentName?.trim()?.toUpperCase()?.split(' ')[1] !== undefined ? currentName?.toUpperCase()?.split(' ')[1][0] : '';
        return `${avatarname0 != undefined ? avatarname0 : ''}${avatarname1 != undefined ? avatarname1 : ''}`;
    }
    return null;
};

const AsyncTableListing = ({
    title = '',
    titleContainer,
    columns,
    data,
    actions: Actions,
    cutomLink: CustomLink,
    page,
    setPage,
    count,
    tableStyle = { minWidth: 700 },
    footerSummary,
    sort,
    setSort,
    limit,
    setLimit,
    render,
    pagination = true,
    SelectComponent = (() => <></>),
    openKycStatusUpdatePopup= (() => {})
}) => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const classes = useStyles();
    const checkAllRef = useRef(false);
    const [isCheckboxVisible, setCheckboxVisible] = useState(false);
    const navigate = useNavigate();

    const handleChange = () => {
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = checkAllRef.current.checked;
        });
    };
    // const {
    //     mutateAsync: downloadFileData,
    //     refetch: refetchDocuments,
    //     isLoading: isDocumentssLoading,
    //     isRefetching: isDocoumetsRefetching
    // } = DownloadStampFile();

    // const handleFileClick = (item) => {
    //     // setIsExportLoading(true);
    //     downloadFileData({ id: item.stamp_document_id }).then(({ data }) => {
    //         const fileUrl = data.data[0].fileUrl;
    //         const link = document.createElement('a');
    //         link.href = fileUrl;
    //         link.target = '_blank';
    //         const urlParts = fileUrl.split('/');
    //         const fileName = urlParts[urlParts?.length - 1];
    //         const originalFileName = decodeURIComponent(fileName.split('?')[0]); // Remove any query parameters
    //         if (originalFileName) {
    //             link.setAttribute('download', originalFileName);
    //         }
    //         link.click();
    //     });
    // };

    return (
        <>
            {/* // <Paper>
        //     {title && (
        //         <Box
        //             sx={{
        //                 paddingLeft: '8px',
        //                 paddingTop: '8px',
        //                 paddingBottom: '8px',
        //                 display: 'flex',
        //                 alignItems: 'center',
        //                 justifyContent: 'space-between'
        //             }}
        //         >
        //             <h2 style={{ margin: 0, paddingBottom: '8px' }}>{title}</h2>
        //             {titleContainer && titleContainer}
        //         </Box>
        //     )} */}
            <TableContainer sx={{ maxHeight: '90vh', borderRadius: '8px' }}>
                {/* , border: 1, borderColor: 'rgba(224, 224, 224, 1)' */}
                <PerfectScrollbar style={{ height: '100%', maxHeight: '90vh', overflowX: 'hidden' }}>
                    <Table sx={tableStyle} className={`${classes.customTable} add-border deal-table`} aria-label="customized table" stickyHeader>
                        <TableHead sx={{ position: 'sticky', top: '-1px', zIndex: 3 }}>
                            <TableRow
                                sx={{
                                    '& .MuiTableCell-sizeMedium': {
                                        padding: '10px 16px 10px 16px' // <-- arbitrary value
                                    }
                                }}
                            >
                                {columns?.map((column, index) => (
                                    <TableCell key={index} align={column.align} style={{
                                        minWidth: column.minWidth,
                                        position: index === column.fixed_row_index ? 'sticky' : 'relative',
                                        left: index === column.fixed_row_index ? 0 : 'auto',
                                        zIndex: index === column.fixed_row_index ? 2 : 'auto',
                                        // backgroundColor: '#F3F4FA',
                                        // color: 'rgb(40, 53, 147)',
                                    }} padding={'normal'}>
                                        {column.label != 'Checkbox' ? (
                                            column.label
                                        ) : (
                                            <input
                                                type="checkbox"
                                                name="primery_checked"
                                                color="primary"
                                                onChange={handleChange}
                                                ref={checkAllRef}
                                                className='cursor-pointer'
                                            />
                                        )}
                                        {column.sort && (
                                            <IconButton
                                                onClick={() => {
                                                    setSort((oldSort) => {
                                                        if (oldSort == 1) return -1;
                                                        else return 1;
                                                    });
                                                    setPage(1);
                                                }}
                                                style={{ position: 'relative', top: '-2px' }}
                                            >
                                                {sort == -1 && <SouthIcon style={{ fontSize: '14px', color: '#fff' }} />}{' '}
                                                {/* descending sorting */}
                                                {sort == 1 && <NorthIcon style={{ fontSize: '14px', color: '#fff' }} />}{' '}
                                                {/* assending sorting */}
                                                {/* {sort==0 && <NorthIcon style={{fontSize: '14px', color: '#999'}} />}  */} {/* No sorting */}
                                            </IconButton>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((row, idx) => (
                                <TableRow key={row['_id']}>
                                    {Object.keys(columns).map((_, index) => {
                                        if (columns[index].type === 'name' && (columns[index].image === true)) {
                                            {/* console.log('name'); */ }
                                            const isFixed = columns[index].fixed === true;
                                            return (
                                                <TableCell sx={{ position: isFixed ? 'sticky' : 'static', left: isFixed ? 0 : 'auto', zIndex: isFixed ? 2 : 0 }}>
                                                    <Stack flexDirection={'row'} onClick={() => {
                                                        const currentURL = document.URL;
                                                        currentURL.split("/")[currentURL.split("/")?.length - 1] == 'investors' ?
                                                            navigate(`viewInvestor/${row['_id']}`) : navigate(`/investors/viewInvestor/${row['_id']}`);
                                                    }} sx={{ cursor: 'pointer' }} alignItems={'center'} gap={1} justifyContent={'flex-start'}>
                                                        {row['profile_image'] !== undefined && row['profile_image'] !== '' ? (
                                                            <img
                                                                src={row['profile_image_url']}
                                                                width={30}
                                                                height={30}
                                                                style={{ borderRadius: '50%' }}
                                                                alt={row['profile_image']}
                                                            />
                                                        ) : (
                                                            <Avatar sx={{ width: 30, height: 30, fontSize: '0.8rem', color: 'white', backgroundColor: 'rgb(0, 0, 255)' }}>
                                                                {stringAvatar(row[columns[index].type])}
                                                            </Avatar>
                                                        )}
                                                        <span style={{ fontWeight: 900 }}>{row[columns[index].type]} </span>
                                                    </Stack>
                                                </TableCell>
                                            );
                                        }
                                        else if (columns[index].link == true) {
                                            const isFixed = !!(columns[index].fixed);
                                            const hasImage = !!(columns[index].image);
                                            const isTypeSyndicateName = !!(columns[index]?.type == "syndicate_name");
                                            // console.log(columns[index])
                                            return <TableCell sx={{ position: isFixed ? 'sticky' : 'static', left: isFixed ? 0 : 'auto', textAlign: isTypeSyndicateName ? "center" : 'auto' }}>
                                                <CustomLink row={row} column={columns[index]}>
                                                    {hasImage ? <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
                                                        {row['image'] !== undefined && row['image'] !== '' ? (
                                                            <img
                                                                src={row['image']}
                                                                width={30}
                                                                height={30}
                                                                style={{ borderRadius: '50%' }}
                                                                alt={row['image_name']}
                                                            />
                                                        ) : (
                                                            <Avatar sx={{ width: 30, height: 30, fontSize: '0.8rem', color: 'white', backgroundColor: 'rgb(0, 0, 255)' }}>
                                                                {stringAvatar(row[columns[index].type])}
                                                            </Avatar>
                                                        )}
                                                        <span style={{ fontWeight: 900 }} >{row[columns[index]?.type]?.length > columns[index]?.trim
                                                            ? `${row[columns[index]?.type].substring(0, columns[index]?.trim - 3)}...`
                                                            : row[columns[index]?.type]}</span>
                                                    </Stack> :
                                                        <span style={{ fontWeight: 900, textAlign: 'center' }}>
                                                            {row[columns[index]?.type] != undefined && <> {row[columns[index]?.type]?.length > columns[index]?.trim
                                                                ? `${row[columns[index]?.type].substring(0, columns[index]?.trim - 3)}...`
                                                                : `${row[columns[index]?.type]}`}
                                                            </>}
                                                        </span>}
                                                </CustomLink>
                                            </TableCell>
                                        }
                                        else if (columns[index].fixed) {
                                            const isStickyColumn = columns[index].fixed_column_index;
                                            return (
                                                <TableCell
                                                    key={index}
                                                    align={columns[index].align}
                                                    style={{
                                                        position: isStickyColumn ? 'sticky' : 'static',
                                                        left: isStickyColumn ? 0 : 'auto',
                                                        zIndex: isStickyColumn ? 2 : 'auto'
                                                    }}
                                                >
                                                    {row[columns[index].type]}
                                                </TableCell>
                                            )
                                        }
                                        else if (SelectComponent && columns[index].selectable == true) {
                                            return (
                                                <TableCell key={index} align="center">
                                                    <SelectComponent row={row} rowIndex={idx} colIndex={index} type={columns[index].type} />
                                                </TableCell>
                                            );
                                        }
                                        else if (Actions && columns[index].type == 'actions') {

                                            return (
                                                <TableCell key={index} align="right">
                                                    <Actions row={row} />
                                                </TableCell>
                                            );
                                        } else if (columns[index].type === 'filename' && columns[index].link) {
                                            // Render the file name as a link
                                            return (
                                                <TableCell key={index} align={columns[index].align}>
                                                    <a
                                                        href={row.fileUrl}
                                                        target="_blank"
                                                        // onClick={(e) => handleFileClick(row)}
                                                        tabindex={0}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                // handleFileClick(row);
                                                            }
                                                        }}
                                                        rel="noopener noreferrer"
                                                        className="file-link"
                                                    >
                                                        {row[columns[index].type]}
                                                    </a>
                                                </TableCell>
                                            );
                                        } else if (columns[index].type == 'startup_status') {
                                            // console.log(row.startup_status[0],"row.startup_statusrow.startup_statusrow.startup_status")
                                            if (row.startup_status != undefined) {
                                                return (
                                                    <TableCell key={index} align={columns[index].align}>
                                                        <Tooltip title={row.startup_status[0].name}>
                                                            <Chip
                                                                size="small"
                                                                label={row.startup_status[0].name}
                                                                sx={{
                                                                    backgroundColor: row.startup_status[0].background_color,
                                                                    color: row.startup_status[0].font_color,
                                                                    border: 'none',
                                                                    minWidth: '100px',
                                                                    fontSize: '12px',
                                                                    borderRadius: '4px',
                                                                    fontWeight: 700
                                                                    // borderWidth: '0.5px',
                                                                    // borderStyle: 'solid',
                                                                    // borderColor: chipInfo.color
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                );
                                            } else {
                                                return (
                                                    <TableCell key={index} align={columns[index].align}>
                                                        -
                                                    </TableCell>
                                                );
                                            }
                                        } else if (columns[index].type == 'checkbox') {
                                            if (row.status == 'Uploaded' && row.last_email_status == true) {
                                                return (
                                                    <TableCell key={index} align={columns[index].align}>
                                                        <input
                                                            type="checkbox"
                                                            name="checked"
                                                            color="primary"
                                                            className="ElementCheck cursor-pointer"
                                                            value={row._id}
                                                        />
                                                    </TableCell>
                                                );
                                            } else {
                                                return (
                                                    <TableCell key={index} align={columns[index].align}>
                                                        -
                                                    </TableCell>
                                                );
                                            }
                                        } else {
                                            if (columns[index].type == 'logo') {
                                                return (
                                                    <TableCell key={index} align={columns[index].align}>
                                                        <img
                                                            height={40}
                                                            src={`${row.logo}?w=10&h=10`}
                                                            srcSet={`${row.logo}?w=10&h=10&time=` + Date.now()}
                                                            alt={row.name}
                                                            loading="lazy"
                                                        />
                                                    </TableCell>
                                                );
                                            } else if (columns[index].type == 'admin_image') {
                                                return (
                                                    <TableCell key={index} align={columns[index].align}>
                                                        <img
                                                            height={40}
                                                            width={40}
                                                            src={`${row.admin_image}?w=10&h=10`}
                                                            srcSet={`${row.admin_image}?w=10&h=10`}
                                                            alt={row.name}
                                                            loading="lazy"
                                                            style={{
                                                                borderRadius: '50%'
                                                            }}
                                                        />
                                                    </TableCell>
                                                );
                                            } else if (columns[index].type == 'role') {
                                                return (
                                                    <TableCell key={index} align={columns[index].align}>
                                                        {row.role}
                                                    </TableCell>
                                                );

                                                // {
                                                //     row[columns[index].type];
                                                // }
                                            } else if (columns[index].price_tooltip) {
                                                return (
                                                    <TableCell key={index} align={columns[index].align}>
                                                        <Tooltip title={convertToIndianCurrencyWords(row[columns[index].tooltip_amount])}>
                                                            {row[columns[index].type]}
                                                        </Tooltip>
                                                    </TableCell>
                                                );
                                            } else if (columns[index].link) {
                                                if (columns[index].trim) {
                                                    return (
                                                        <TableCell key={index} align={columns[index].align}>
                                                            <CustomLink row={row}>
                                                                {row[columns[index]?.type]?.length > columns[index]?.trim
                                                                    ? `${row[columns[index]?.type].substring(0, columns[index]?.trim - 3)}...`
                                                                    : row[columns[index]?.type]}
                                                            </CustomLink>
                                                        </TableCell>
                                                    );
                                                }
                                                return (
                                                    <TableCell key={index} align={columns[index].align}>
                                                        <CustomLink row={row}>{row[columns[index].type]}</CustomLink>
                                                    </TableCell>
                                                );
                                            } else if (columns[index].status) {
                                                let chipInfo = { label: 'Pending', color: '#d4a72d', backgroundColor: '#fef8e8' };
                                                if (row[columns[index].type] == 'Approved') {
                                                    chipInfo = { label: 'Approved', color: '#60cb5e', backgroundColor: '#ebfaeb' };
                                                } else if (row[columns[index].type] == 'KYC In Review') {
                                                    chipInfo = { label: 'In Review', color: '#f68345', backgroundColor: '#f8dac6' };
                                                } else if (row[columns[index].type] == 'KYC Correction') {
                                                    chipInfo = { label: 'Correction', color: '#f46363', backgroundColor: '#fdebeb' };
                                                } else if (row[columns[index].type] == 'KYC Details Submitted') {
                                                    chipInfo = { label: 'Submitted', color: '#39739d', backgroundColor: '#e1ecf4' };
                                                }
                                                return (
                                                    <TableCell key={index} align={columns[index].align}>
                                                        {/* <CustomLink row={row}>{row.color_code}</CustomLink> */}
                                                        <Chip
                                                            onClick={() => columns[index]?.clickable && openKycStatusUpdatePopup(row?._id)}
                                                            size="small"
                                                            label={chipInfo.label}
                                                            sx={{
                                                                backgroundColor: chipInfo.backgroundColor,
                                                                color: chipInfo.color,
                                                                border: 'none',
                                                                minWidth: '100px',
                                                                fontSize: '12px',
                                                                borderRadius: '4px',
                                                                fontWeight: 700
                                                                // borderWidth: '0.5px',
                                                                // borderStyle: 'solid',
                                                                // borderColor: chipInfo.color
                                                            }}
                                                        />
                                                    </TableCell>
                                                );
                                            } else if (columns[index].tooltip == true) {
                                                if (columns[index].trim) {
                                                    return (
                                                        <TableCell key={index} align={columns[index].align}>
                                                            {row[columns[index].type]?.length > columns[index].trim
                                                                ? `${row[columns[index].type].substring(
                                                                    0,
                                                                    parseInt(columns[index].trim) - 3
                                                                )}...`
                                                                : row[columns[index].type]}
                                                        </TableCell>
                                                    );
                                                }
                                                return (
                                                    <TableCell key={index} align={columns[index].align}>
                                                        {row[columns[index].type]}
                                                    </TableCell>
                                                );
                                            }
                                            if (columns[index].trim) {
                                                return (
                                                    <TableCell key={index} align={columns[index].align}>
                                                        {row[columns[index].type]?.length > columns[index].trim
                                                            ? `${row[columns[index].type].substring(0, parseInt(columns[index].trim) - 3)}...`
                                                            : row[columns[index].type]}
                                                    </TableCell>
                                                );
                                            }
                                            return (
                                                <TableCell key={index} align={columns[index].align}>
                                                    {row[columns[index].type]}
                                                </TableCell>
                                            );
                                        }
                                    })}
                                </TableRow>
                            ))}
                            {data?.length < 1 && (
                                <TableRow>
                                    <TableCell colSpan={columns?.length} style={{ textAlign: 'center' }}>
                                        <Typography variant="h4">No Records Found!</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </PerfectScrollbar>

            </TableContainer>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: !!footerSummary ? 'space-between' : 'flex-end',
                    marginTop: '16px',
                    marginLeft: '10px'
                }}
            >
                {!!footerSummary && footerSummary}
                <Box display={'flex'}>
                    {pagination && (
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={limit}
                            label="Row per page"
                            size="small"
                            onChange={(event) => {
                                setLimit(event.target.value);
                                setPage(1);
                            }}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                    )}
                    {pagination && <Pagination page={page} count={Math.ceil(count / limit)} onChange={(e, value) => setPage(value)} />}
                </Box>
            </Box>
            {/* </Paper> */}
        </>
    );
};
AsyncTableListing.propTypes = {
    title: PropTypes.node,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    actions: PropTypes.func
};
export default AsyncTableListing;
