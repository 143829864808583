import React, { useEffect, useState } from "react";
import Information from './Information';
import PreScreening from './PreScreening';
import UserDetails from './UserDetails';
import StartupDetails from './StartupDetails';
import OtherDetails from './OtherDetails';
import ReferalDetails from './ReferalDetails';
import Thankyou from './Thankyou';

function Home() {

  const [step_num, setStep] = useState(1);

  if(step_num == 1 ){
    return (
      <Information  setStep={setStep}/>
    )
  }else if(step_num == 2){
    return (
    <PreScreening  setStep={setStep}/>
    )
  }else if(step_num == 3){
    return (
    <UserDetails  setStep={setStep}/>
    )
  }else if(step_num == 4){
    return (
    <StartupDetails  setStep={setStep}/>
    )
  }else if(step_num == 5){
    return (
    <OtherDetails  setStep={setStep}/>
    )
  }else if(step_num == 6){
    return (
      <ReferalDetails  setStep={setStep}/>
      )
  }else if(step_num == 7){
    return (
      <Thankyou  setStep={setStep}/>
      )
  }
}
export default Home;
