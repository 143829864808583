// Packages
import { BrowserRouter } from 'react-router-dom';

// Configs
import config from './config';

// CSS Files
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

// Routes
//import AppRoutes from './AppRoutes';

import Routes from './routes';
import { ThemeProvider } from '@mui/material/styles';

// defaultTheme
import themes from '../src/themes';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setCustomisation } from './store/actions/setCustomisationAction';

const App = () => {
    // const customization = useSelector((state) => state.customization);
    const isOpen = useSelector((state) => state.menuOpenReducer.data);
    const opened = useSelector((state) => state.setMenuReducer.data);
    const fontFamily = useSelector((state) => state.setFontFamilyReducer.data);
    const borderRadius = useSelector((state) => state.setBorderRadiusReducer.data);
    const customization = {
        isOpen: isOpen,
        opened: opened,
        fontFamily: fontFamily,
        borderRadius: borderRadius
    }
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setCustomisation(customization))
    }, [customization])

    return (
        <BrowserRouter basename={config.basename}>
            <ThemeProvider theme={themes(customization)}>
                <Routes />
            </ThemeProvider>
        </BrowserRouter>
    );
};

export default App;
