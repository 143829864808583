import React, { useState, useEffect } from 'react';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import Card from '../../../components/Card';
import { useDispatch, useSelector } from 'react-redux';
import { AttachMisReportAPI, FetchReportDetails, MSIDownloadDocument, DeleteDocumentForMIS } from '../../../api';
import { toast, ToastContainer } from 'react-toastify';
import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Divider,
  IconButton,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { makeStyles, withStyles } from '@mui/styles';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Loading from '../../../components/Loading';
import { Delete } from '@mui/icons-material';
import ModalComponent from '../../../components/Modal';
import DeleteDocumentPopup from './popups/DeleteDocumentPopup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '10px 0 0 10px',
        borderRightWidth: '0px',
      },
    },
  },
})(TextField);

const useStyles = makeStyles({
  dataRange: {
    ".base-Popper-root .MuiPickersPopper-root > .MuiPaper-root .MuiPaper-elevation": {
      display: "none !important"
    }
  }
})

const documents_types = [
  { label: "The Summary Report", value: "the_summary_report" },
  { label: "Trend Report", value: "trend_report" },
  { label: "Monthly MIS Report", value: "monthly_mis_report" },
  { label: "Exception Reports", value: "exception_reports" },
  { label: "Financial MIS Report", value: "financial_mis_report" },
  { label: "Inventory MIS Report", value: "inventory_mis_report" },
  { label: "Sales MIS Report", value: "sales_mis_report" },
  { label: "Budget MIS Report", value: "budget_mis_report" },
  { label: "Fund Flow Statement Report", value: "fund_flow_statement_report" },
  { label: "Actual Vs Budget MIS Report", value: "actual_vs_budget_mis_report" },
  { label: "Others", value: "others" },
]

const MisReport = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id: misReportId } = useParams();
  useEffect(() => {
    // Adding custom styles to ensure hover color is updated
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      .ql-editor p {
        color: black !important;
      }
      .ql-container {
        background-color: #fafafa !important;
        border-radius: 0 0 4px 4px !important;
        overflow: hidden;
      } 
      .ql-toolbar {
        background-color: #fafafa !important;
        border-radius: 4px 4px 0 0 !important;
      } 
    `;
    document.head.appendChild(styleElement);
  
    // Cleanup on component unmount
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);
  
  
  
  
  const isEditMode = !!misReportId;
  const [documents, setDocuments] = useState([
    { name: '', file: null, upload_type: '', specify: '' },
  ]);
  const [value, setValue] = React.useState([null, null]);
  const [openDatePicker, setOpenDatePicker] = React.useState(false)
  const [documentOpen,setDocumentOpen] = useState(false);
  const [activeDocument,setActiveDocument] = useState(null);

  const { data: misReportData, isLoading: isMisDetailsLoading, refetch: refetching } = FetchReportDetails(misReportId);
  const { mutateAsync: deleteDocumentAsync, status: DeleteStatus, error: DeleteError } = DeleteDocumentForMIS();
  // const location = useLocation();
  // const data = location.state;
  const {
    mutateAsync: attachMisReport,
    status: attachMisReportStatus,
    error: attachMisReportError,
    isLoading,
    data: misAttachData
  } = AttachMisReportAPI(misReportId);
  const { mutateAsync: downloadDocument } = MSIDownloadDocument();
  const navigate = useNavigate();
  useEffect(() => {
    if (attachMisReportStatus === 'success') {
      toast.success(`Report ${ !isEditMode ? "Created" : "Updated"}!`, {
        onClose: () => {
          navigate('/reports');
        },
      });
    } else if (attachMisReportStatus === 'error') {
      toast.error(attachMisReportError?.response?.data?.message);
      // toast.error(misAttachData?.message || 'Error updating report');
      // console.log(attachMisReportError?.response?.data?.message,"data")
    }
  }, [attachMisReportStatus, attachMisReportError]);

  useEffect(() => {
    if (DeleteStatus === 'success') {
      refetching();
      toast.success('Document Deleted!', {
      });
      setDocumentOpen(false)
      setActiveDocument(null)
    } else if (DeleteStatus === 'error') {
      toast.error(DeleteError?.response?.data?.message);
      // toast.error(misAttachData?.message || 'Error updating report');
      // console.log(attachMisReportError?.response?.data?.message,"data")
    }
  }, [DeleteStatus, DeleteError]);

  const [min_date, setMinDate] =React.useState(null);
  const [max_date, setMaxDate] =React.useState(null);

  const initialValues = {
    to_date: null,
    from_date: null,
    title: "",
    overview: '',
    documents: [{ name: '', file: null, document_type: "" }],
  };
  // console.log(misReportData, "DATA")
  const formik = useFormik({
    initialValues: isEditMode && !isMisDetailsLoading ? misReportData : initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append('title', values?.title);
      formData.append('overview', values?.overview);
      formData.append('from_date', moment(values?.from_date).format('MM-DD-YYYY'));
      formData.append('to_date', moment(values?.to_date).format('MM-DD-YYYY'));
      if (values.documents) {
        values.documents.forEach((doc) => {
          formData.append('name[]', doc.name);
          formData.append('document_type[]', doc.document_type);
            formData.append('specify[]', doc.specify);

          // formData.append('upload_type[]', doc.upload_type);
          // formData.append('specify[]', doc.specify);
          if (doc.file?.size) {
            formData.append('documents', doc.file);
          } else {
            formData.append(`documents[${doc.document_id}]`, '');
          }
        });
      }
      attachMisReport({ payload: formData });
    },
  });


  const handleButtonClick = () => {
    formik.handleSubmit();
  };

  const handleDownload = async (fileName, event) => {
    event.preventDefault();
    try {
      const response = await downloadDocument({ payload: { file_name: fileName } });
      const downloadUrl = response.data.data.url;
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      link.target = '_blank';
      link.click();
    } catch (error) {
    }
  };

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    formik.setFieldValue(`documents.${index}.file`, file);
  };

  const handleOpen = () => {
    if (formik.values.to_date) {
      // If 'to_date' DatePicker is open and 'from_date' has a value, prevent opening
      return;
    }
    setOpenDatePicker(true);
  };

  const handleClose = () => {
    if (formik.values.to_date) {
      setOpenDatePicker(false);
    }
  };

  if (isMisDetailsLoading) {
    return <Grid container xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100%"}>
      <Loading />
    </Grid>
  }

  const documentHandleClose = () => {
    setDocumentOpen(false)
    setActiveDocument(null)
  }

  const handleConfirm = (field) => {
    deleteDocumentAsync({id : field?.document_id})
  }

  const handleOpenPopup = (row) => {
    setDocumentOpen(true)
    setActiveDocument(row)
  }

  const handelOverviewChange =(value) =>{
    formik.setFieldValue('overview' ,value)


  }


  return (
    <>
    <ModalComponent open={documentOpen} onClose={documentHandleClose} closeIcon={true} style={{ width: '600px' }} disableScrollLock={true}>
        <DeleteDocumentPopup activeRow={activeDocument} handleClose={documentHandleClose} handleConfirm={handleConfirm} refetchTransactions={refetching} />
      </ModalComponent>
      {/* <LocalizationProvider dateAdapter={AdapterMoment}> */}
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Card
            style={{ paddingLeft: '16px', paddingRight: '16px' }}
            title={isEditMode ? 'Edit Report' : 'Upload Report'}
            backTo={'/dashboard'}
            secondary={
              <div className="text-end">
                <button type="button" onClick={handleButtonClick} disabled={isLoading} className="save-btn">
                  <div className="d-flex align-items-center">
                    {isLoading && <div className="spinner-border-save spinner-border-sm-save me-2" />}
                    {isLoading ? 'Loading... ' : 'Upload'}
                  </div>
                </button>
              </div>
            }
          >
            <div className="card-body">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id='title'
                    name='title'
                    label="Report Name"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6} container display={"flex"} alignItems="center" justifyContent={"space-between"}>
                  {/* <Grid item xs={12} sm={6}> */}
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      name={`from_date`}
                      sx={{width: "47%"}}
                      label="From Date"
                      value={formik.values.from_date}
                      onChange={(value) => {formik.setFieldValue(`from_date`, value); setMinDate(value)}}
                      maxDate={max_date}
                      renderInput={(params) => <TextField  {...params} fullWidth />}
                      format='DD/MM/YYYY'
                    />
                  </LocalizationProvider>
                  <Box sx={{maxWidth: "20px"}}>-</Box>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      name={`to_date`}
                      label="To Date"
                      sx={{width: "47%"}}
                      value={formik.values.to_date}
                      minDate={min_date}
                      onChange={(value) => {formik.setFieldValue(`to_date`, value);setMaxDate(value)}}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                      format='DD/MM/YYYY'
                    />
                  </LocalizationProvider>
                  {/* </Grid> */}
                </Grid>
                <Grid item xs={12} sm={12}>
                  {/* <TextField
                    multiline
                    fullWidth
                    minRows={4}
                    id='overview'
                    name='overview'
                    label="Overview"
                    value={formik.values.overview}
                    onChange={formik.handleChange}
                  /> */}
                  <ReactQuill
                    style={{
                      height: '150px' }}
                    placeholder='Overview'
                    onChange={handelOverviewChange}
                    value={formik.values.overview}
                    
                  />
                </Grid>
              </Grid>

              <Divider style={{ marginTop: '70px', marginBottom: '20px' }} />
              <FieldArray name='documents' render={(arrayHelpers) => {
                return <>
                  {formik.values.documents && formik.values.documents?.length > 0 && formik.values.documents?.map((field, index) => (
                    <div key={index}>
                      {/* <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        name={`documents.${index}.from_date`}
                        label="From Date"
                        value={field.from_date}
                        onChange={(value) => formik.setFieldValue(`documents.${index}.from_date`, value)}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                      />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        name={`documents.${index}.to_date`}
                        label="To Date"
                        value={field.to_date}
                        onChange={(value) => formik.setFieldValue(`documents.${index}.to_date`, value)}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                      />
                      </LocalizationProvider>
                    </Grid>
                  </Grid> */}
                      <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            id={`dynamic-name-${index}`}
                            name={`documents.${index}.name`}
                            label="Document Name"
                            value={formik.values.documents[index].name}
                            onChange={(e) => formik.setFieldValue(`documents.${index}.name`, e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CustomTextField
                              fullWidth
                              disabled
                              label="Upload File"
                              value={field.file ? field.file.name : formik.values.documents[index].file_name || ''}
                            />
                            <Button
                              variant="contained"
                              component="label"
                              disableElevation
                              style={{
                                borderRadius: '0px 10px 10px 0px',
                                height: '51px',
                                marginLeft: '-1px',
                                color: '#fff',
                                backgroundColor: '#ff6624',
                              }}
                            >
                              Upload
                              <input
                                hidden
                                accept="application/pdf, image/*"
                                type="file"
                                id={`dynamic-file-${index}`}
                                onChange={(e) => handleFileChange(e, index)}
                              />
                            </Button>
                          </div>
                          {isEditMode && !!misReportData && field.file_name && !field.file && (
                            <span style={{ marginTop: '5px' }}>
                              <a href="#" onClick={(event) => handleDownload(field.file_name, event)}>
                                {String(field.file_name)?.length > 60 ? String(field.file_name)?.substring(0, 60) + "..." : field.file_name}
                              </a>
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel id={`dynamic-upload_type-label-${index}`}>Document Type</InputLabel>
                            <Select
                              labelId={`dynamic-upload_type-label-${index}`}
                              id={`dynamic-upload_type-${index}`}
                              name={`documents.${index}.document_type`}
                              value={formik.values.document_type != "" ? field.document_type : formik.values.documents[index].document_type}
                              onChange={(e) => {
                                formik.setFieldValue(`documents.${index}.document_type`, e.target.value);
                              }}
                              label="Document Type"
                            >
                              {documents_types?.map(item => <MenuItem value={item?.value}>{item?.label}</MenuItem>)}
                            </Select>
                            {formik.touched.documents?.[index]?.document_type && formik.errors.documents?.[index]?.document_type && (
                              <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                                {formik.errors.documents?.[index]?.document_type}
                              </div>
                            )}
                          </FormControl>
                        </Grid>
                        {/* {console.log(formik.values.documents[index].specify,field.specify)} */}
                        <Grid item container xs={12} sm={6} display={"flex"} flexDirection={"row-reverse"} alignItems={"center"} gap={1}>
                          {index !== 0 && <Grid item xs={1} justifyContent="flex-end">
                            <IconButton onClick={() => field?.document_id != undefined ? handleOpenPopup(field) : arrayHelpers.remove(index)} style={{ borderRadius: '6px', color: 'red' }}>
                              <Delete />
                            </IconButton>
                          </Grid>}
                          {index == formik.values.documents.length - 1 && <Grid item xs={1} justifyContent="flex-end">
                            <IconButton onClick={() => arrayHelpers.push({ name: '', file: null, document_type: "" })} style={{ borderRadius: '6px', color: '#ff6624' }}>
                              <AddIcon />
                            </IconButton>
                          </Grid>}
                          <Grid item xs={index == formik.values.documents.length - 1 ? (index == 0 ? 10.75 : 9.5) : index == 0 ? 12: 10.75 }>
                          {formik.values.documents[index].document_type === 'others' && (
                            <TextField
                              fullWidth
                              id={`dynamic-specify-${index}`}
                              name={`documents.${index}.specify`}
                              label="Please Specify"
                              width={"100%"}
                              defaultValue={field?.specify}
                              value={formik.values.documents[index].specify}
                              onChange={(e) => formik.setFieldValue(`documents.${index}.specify`, e.target.value)}
                            />
                          )}
                          </Grid>
                        </Grid>
                      </Grid>
                      {index < formik.values.documents.length - 1 && <Divider style={{ margin: '25px 0' }} />}
                    </div>
                  ))}
                  {/* {!isEditMode && ( */}
                  {/* <Grid container justifyContent="flex-end" style={{ marginTop: '10px' }}>
                    <IconButton onClick={() => arrayHelpers.push({ name: '', file: null, document_type: "" })} style={{ borderRadius: '6px', color: '#ff6624' }}>
                      <AddIcon />
                    </IconButton>
                  </Grid> */}
                </>
              }}
              />
              {/* // )} */}
            </div>
          </Card>
        </Form>
      </FormikProvider>
      {/* </LocalizationProvider> */}
      {/* <ToastContainer /> */}
    </>
  );
};

export default MisReport;
