import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MainLogo } from "../../assets/img";
import hamIcon from "../../assets/img/ham-icon.svg";
import crossIcon from "../../assets/img/close-icon.svg";
import DealLeaftMenu from "../../views/main/DealLeaftMenu";
import avtarImg from "../../assets/img/dummy-profile.png";
import { useDispatch, useSelector } from "react-redux";
import { saveProfileImage } from "../../store/actions/saveProfileImageAction"

// window.location.hostname

const Header = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const profileImage = useSelector(state => state.saveProfileImageReducer.data)

  const isLogin = Cookies.get("apiToken")?.trim()?.length > 0

  console.log("isLogin", isLogin)

  let loggdenInClass = isLogin ? "" : "logo-login-class";

  // const userDetail = Cookies.get("userData") ? JSON.parse(Cookies.get("userData")) : {};

  // console.log("userData", userDetail)

  const profileDetail = Cookies.get("profileImage")

  const [hamActive, setHamActive] = useState(false);
  const [udActive, setUdActive] = useState(false);
  const [profile, setProfile] = useState(null);
  const [profileSrc, setProfileSrc] = useState(avtarImg)

  const uDropdown = () => {
    setUdActive(!udActive);
  };

  const hamButton = () => {
    setHamActive(!hamActive);
  };

  const closeHmButton = () => {
    setHamActive(false);
  };

  useEffect(() => {
    console.log("profileDetail", profileImage)
    if (profileImage != null) {
      setProfile(profileImage)
    } else {
      setProfile(Cookies.get("profileImage"))
      dispatch(saveProfileImage(Cookies.get("profileImage")))
    }
  }, [profileDetail])

  useEffect(() => {
    if (profile == null) {
      setProfileSrc(avtarImg)
    } else {
      console.log("profile src", profile)
      setProfileSrc(profile)
    }
  }, [profile])

  const renderRightComponent = () => {
    return (
      <div className="d-flex align-items-center">
        {
          isLogin ? renderDropdown() : <div />
        }
      </div>
    )
  }

  const renderDropdown = () => {
    return (
      <div className="btn-group ms-4 ud-block">
        <button
          type="button"
          className="btn dropdown-toggle user-dropdown d-flex align-items-center" data-bs-toggle="dropdown" id="defaultDropdown" aria-expanded="false" data-bs-auto-close="true">
          <div className="profile-circle-header">
            <img src={profileSrc} alt="" />
          </div>
          <div className="ud-name">
            {/* {userDetail.name ? userDetail.name : ""} */}
          </div>
          <span className="ud-arrow"></span>
        </button>
        <ul className={`dropdown-menu dropdown-menu-end ud-menu`} aria-labelledby="defaultDropdown">
          <li>
            <Link to="/my-profile" onClick={uDropdown}>
              <span className="lmenu-icon svg-icon">
                <i className="bi bi-person"></i>
              </span> My Profile
            </Link>
          </li>
          <li>
            <Link onClick={() => {
              uDropdown();
              setTimeout(() => { navigate("/logout") }, 150);
            }}>
              <span className="lmenu-icon logout-icon"></span> Logout
            </Link>
          </li>
        </ul>
      </div>
    )
  }

  return (
    <>
      <header className="header-wrap">
        <div className="container">
          <div className={`header-block d-flex align-items-center justify-content-between ${loggdenInClass}`}>
            <div className="d-flex align-items-center">
              {
                isLogin && <button type="button" className="mobile-menuicon" onClick={hamButton}>
                  <img src={hamIcon} alt="icon" />
                </button>
              }
              <div>
                <Link to="/">
                  <img src={MainLogo} alt={process.env.REACT_APP_APPLICATION_NAME} />
                </Link>
              </div>
            </div>
            {
              renderRightComponent()
            }
          </div>
        </div>
      </header>
      <div className={`overflow-bg ${hamActive ? "show" : ""}`} onClick={closeHmButton} />
      <div className={`ham-menu-block d-none ${hamActive ? "show" : ""}`}>
        <div className="text-end close-icon cursor-pointer" onClick={closeHmButton}>
          <img src={crossIcon} alt="" />
        </div>
        <DealLeaftMenu
          closeHmButton={closeHmButton}
        />
      </div>
    </>
  );
};

export default Header;
