import React, { useEffect } from "react";
import { Field, Form, Formik, FastField } from "formik";
import * as Yup from "yup";
import BtnSpinner from "../../utils/BtnSpinner";
import {
  CustomInputComponent,
  CustomSelectComponent,
  CustomRadioComponent,
  CustomCheckbox,
} from "../../components/formik/FieldComponents";
import { Link, useNavigate } from "react-router-dom";
import { EmailValidate } from "../../api";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   UPDATE_PERSONALISED_INFORMATION,
//   SET_PAGE,
// } from "../../../../store/actions";
// import { SavePersonalInformation, FetchAllCountries } from "../../../../api";
import { Back } from "../../assets/img";
import { regMatch } from "../../utils/util";

const personalInformation = {
  firstName: "",
  lastName: "",
  email: "",
  mobile_number: "",
  linkedln_page_url: "",
  city_name: ""
};

function PersonalInformation({ setStep }) {
  //   const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user_details, setFormData] = React.useState(false);
  // const [email, setEmail] = React.useState("");

  const {
    status: startUpStatus,
    mutateAsync: validateEmail,
    // refetch:refetchListing,
    data: emailValidationData,
    error,
    isLoading,
  } = EmailValidate();

  //   const {
  //     mutateAsync: savePersonalInformation,
  //     isLoading: isSavePersonalInformationLoading,
  //   } = SavePersonalInformation();
  //   const { data: countries, isLoading: isCountryLoading } = FetchAllCountries();
  // console.log("countries", countries);

  //   const personalInformation = useSelector(
  //     (state) => state.indianCitizenship.personalInformation
  //   );


  const PersonalInformationValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    email: Yup.string().email("Please enter a valid email").required("Email is required").test('unique-email', 'Email is already in use', async function (value) {
      if (value) {
        const isUnique = await validateEmail(value);
        return isUnique.data.statusCode == 403 ? false : true;
      }
      return true;
    }),
    mobile_number: Yup.string().required("Mobile number is required"),
    city_name: Yup.string().required("City is required"),
    linkedln_page_url: Yup.string().matches(regMatch, "Please enter a valid URL").required("Linkedin URL is required"),
  });

  useEffect(() => {
    // Retrieve the JSON string from localStorage
    const storedObject = localStorage.getItem('user_details');

    // Parse the JSON string back into an object and set it in component state
    if (storedObject) {
      setFormData(JSON.parse(storedObject));
    }
  }, []);

  return (
    <section className="mid-wrap">
      <div className="forms-header pt-3 pb-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-2 col-2">
              <Link className="back-arrow">
                <img onClick={() => setStep(2)} src={Back} alt="back" />
              </Link>
            </div>
            <div className="col-md-8 col-8">
              <div className="lg-title text-center">Personal Information</div>
            </div>
            {/* <div className="col-md-2 col-2 text-end">
              <span className="pl-15 sm-title">1/4</span>
            </div> */}
          </div>
          <div className="text-center step-process mt-1">
            <div className="d-inline-flex">
              <span className="process-step selected"></span>
              <span className="process-step"></span>
              <span className="process-step"></span>
              <span className="process-step"></span>
            </div>
          </div>
          {/* <h5 className="card-title md-title mt-2 mb-0 text-center heading-formobile">
            Personal Information
          </h5> */}
        </div>
      </div>
      <div className="pt-0 pb-5 kyc-form-block">
        <div className="container">
          <div className="card form-card mt-4">
            <div className="card-body">
              {/* <h5 className="card-title md-title mb-4 text-center heading-fordesktop">
                Personal Information
              </h5> */}
              <Formik
                initialValues={user_details ? user_details : personalInformation}
                // validateOnChange={false}
                // validateOnBlur={true}
                enableReinitialize={true}
                validationSchema={PersonalInformationValidationSchema}
                onSubmit={(values) => {
                  localStorage.setItem('user_details', JSON.stringify(values));
                  setStep(4)

                }}
              >
                {({ errors, touched, values }) => (
                  <Form className="custom-form">
                    <div className="row">
                      {/* First Name */}
                      <div className="col-md-6 form-group">
                        <label className="required">First Name</label>
                        <Field
                          name="firstName"
                          component={CustomInputComponent}
                          type="text"
                        />
                      </div>

                      {/* Middle Name */}
                      <div className="col-md-6 form-group">
                        <label>Last Name</label>
                        <Field
                          name="lastName"
                          component={CustomInputComponent}
                          type="text"
                        />
                      </div>

                      {/* Last Name */}
                      <div className="col-md-6 form-group">
                        <label className="required">Email</label>
                        <Field
                          name="email"
                          component={CustomInputComponent}
                          type="text"
                        />
                      </div>



                      {/* Mobile Number */}
                      <div className="col-md-6 form-group">
                        <label className="required">Mobile Number</label>
                        <Field
                          name="mobile_number"
                          component={CustomInputComponent}
                          type="number"
                          onWheel={(e) => e.target.blur()}
                        />
                      </div>

                      {/* Email Address */}
                      {/* <div className="col-md-6 form-group">
                        <label>Email Address</label>
                        <Field
                          name="emailAddress"
                          component={CustomInputComponent}
                          type="email"
                        />
                      </div> */}





                      {/* Occupation */}
                      <div className="col-md-6 form-group">
                        <label className="required">City</label>
                        <Field
                          name="city_name"
                          component={CustomInputComponent}
                          type="text"
                        />
                      </div>
                      {/* Referral partner */}
                      <div className="col-md-6 form-group">
                        <label className="required">Linkedin Profile URL </label>
                        <Field
                          name="linkedln_page_url"
                          component={CustomInputComponent}
                          type="text"
                          placeholder="https://in.linkedin.com/url"
                        />
                      </div>

                      {/* Country of citizenship */}
                      {/* <div className="col-md-6 form-group">
                        <label className="required">
                          Select Individual Type
                        </label>
                        <Field
                          name="individual_type"
                          component={CustomSelectComponent}
                          options={IndianCitizenUserType.individual_type}
                        />
                      </div> */}

                      {/* NRI Checkbox */}
                      {/* <div className="col-md-12 form-group">
                        <div className="react-checkbox">
                          <Field
                            name="nriCheckbox"
                            component={CustomCheckbox}
                            label="Are you a Non-Resident Indian (NRI)?"
                          />
                        </div>
                      </div> */}
                    </div>
                    {/* {isSavePersonalInformationLoading ? (
                      <BtnSpinner />
                    ) : ( */}
                    <div className="mt-3 text-center">
                      <button type="submit" className="theme-btn btn w-25">
                        Next
                      </button>
                    </div>
                    {/* )} */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          {/* <div className="text-center mt-30">
            <a href="#" className="white-btn w-360">
              Terms of Use
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default PersonalInformation;
