export const types = {

    PROFILE_IMAGE: 'PROFILE_IMAGE',
    SET_MENU: 'SET_MENU',
    MENU_OPEN: 'MENU_OPEN',
    SET_BORDER_RADIUS: 'SET_BORDER_RADIUS',
    SET_FONT_FAMILY: 'SET_FONT_FAMILY',
    CUSTOMISATION: 'CUSTOMISATION',

    SEND_REQUEST_LOGIN: 'SEND_REQUEST_LOGIN',
    SEND_REQUEST_LOGIN_SUCCESS: 'SEND_REQUEST_LOGIN_SUCCESS',
    SEND_REQUEST_LOGIN_FAILURE: 'SEND_REQUEST_LOGIN_FAILURE',
    CLEAR_REQUEST_LOGIN: 'CLEAR_REQUEST_LOGIN',

    SEND_REQUEST_GET_USER_INFO: 'SEND_REQUEST_GET_USER_INFO',
    SEND_REQUEST_GET_USER_INFO_SUCCESS: 'SEND_REQUEST_GET_USER_INFO_SUCCESS',
    SEND_REQUEST_GET_USER_INFO_FAILURE: 'SEND_REQUEST_GET_USER_INFO_FAILURE',
    CLEAR_REQUEST_GET_USER_INFO: 'CLEAR_REQUEST_GET_USER_INFO',

    SEND_REQUEST_FORGOT_PIN: 'SEND_REQUEST_FORGOT_PIN',
    SEND_REQUEST_FORGOT_PIN_SUCCESS: 'SEND_REQUEST_FORGOT_PIN_SUCCESS',
    SEND_REQUEST_FORGOT_PIN_FAILURE: 'SEND_REQUEST_FORGOT_PIN_FAILURE',
    CLEAR_REQUEST_FORGOT_PIN: 'CLEAR_REQUEST_FORGOT_PIN',

    SEND_REQUEST_CONTACT_US: 'SEND_REQUEST_CONTACT_US',
    SEND_REQUEST_CONTACT_US_SUCCESS: 'SEND_REQUEST_CONTACT_US_SUCCESS',
    SEND_REQUEST_CONTACT_US_FAILURE: 'SEND_REQUEST_CONTACT_US_FAILURE',
    CLEAR_REQUEST_CONTACT_US: 'CLEAR_REQUEST_CONTACT_US',

    SEND_REQUEST_CHANGE_PIN: 'SEND_REQUEST_CHANGE_PIN',
    SEND_REQUEST_CHANGE_PIN_SUCCESS: 'SEND_REQUEST_CHANGE_PIN_SUCCESS',
    SEND_REQUEST_CHANGE_PIN_FAILURE: 'SEND_REQUEST_CHANGE_PIN_FAILURE',
    CLEAR_REQUEST_CHANGE_PIN: 'CLEAR_REQUEST_CHANGE_PIN',
};  