import React, { useEffect, useState } from "react";
import { Field, Form, Formik, FastField } from "formik";
import * as Yup from "yup";
import BtnSpinner from "../../../utils/BtnSpinner";
import {
  CustomInputComponent,
} from "../../../components/formik/FieldComponents";
import { Link, useNavigate } from "react-router-dom";
import { EmailValidate } from "../../../api";
import { Back } from "../../../assets/img";
import Cookies from "js-cookie";
import { regMatch } from "../../../utils/util";

const personalInformation = {
  firstName: "",
  lastName: "",
  email: "",
  mobile_number: "",
  linkedln_page_url: "",
  city_name: ""
};

function UserDetailsView({ setStep }) {
  //   const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [editAccess, setEditAccess] = useState(true);

  const toggleEdit = () => {
    setEditAccess(editAccess => !editAccess);
  };

  const {
    status: startUpStatus,
    mutateAsync: validateEmail,
    // refetch:refetchListing,
    data: emailValidationData,
    error,
    isLoading,
  } = EmailValidate();


  const PersonalInformationValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    email: Yup.string().email("Please enter a valid email").required("Email is required").test('unique-email', 'Email is already in use', async function (value) {
      if (value) {
        const isUnique = await validateEmail(value);
        return isUnique.data.statusCode == 403 ? false : true;
      }
      return true;
    }),
    mobile_number: Yup.string().required("Mobile number is required"),
    city_name: Yup.string().required("City is required"),
    linkedln_page_url: Yup.string().matches(regMatch, "Please enter a valid URL").required("Linkedin URL is required"),
  });

  useEffect(() => {
    // Retrieve the JSON string from localStorage
    const userData = Cookies.get("userData") ? JSON.parse(Cookies.get("userData")) : {};

    // Parse the JSON string back into an object and set it in component state
    if (userData) {
      setUserDetails(userData);
    }
  }, []);

  return (
    <section className="mid-wrap">
      <div className='header-mobile d-md-none border-b'>
        <div className='d-flex align-items-center justify-content-between'>
          <div>
            <Link className="back-btn blue-text d-block" to="/my-profile">
              <img src={Back} alt="arrow" /> Personal Information
            </Link>
          </div>
          {
            <div>
              <button type="button" onClick={toggleEdit} className='theme-btn btn btn-sm' >{editAccess ? 'Edit' : 'Cancel'}</button>
            </div>
          }
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-between cr-heading header-desktop'>
        <div className="md-title text-start fw-700">
          Personal Information
        </div>
        {
          <div>
            <button type="button" onClick={toggleEdit} className='theme-btn btn btn-sm' >{editAccess ? 'Edit' : 'Cancel'}</button>
          </div>
        }
      </div>
      <div className="pt-0 pb-5 kyc-form-block">
        <div className="container">
          <div className="card form-card mt-4">
            <div className="card-body">
              <Formik
                initialValues={userDetails ? userDetails : personalInformation}
                enableReinitialize={true}
                validationSchema={PersonalInformationValidationSchema}
                onSubmit={(values) => {
                  localStorage.setItem('user_details', JSON.stringify(values));
                  setStep(4)

                }}
              >
                {({ errors, touched, values }) => (
                  <Form className="custom-form">
                    <div className="row">
                      <div className="col-md-6 form-group" style={{ opacity: editAccess ? 0.5 : 1 }}>
                        <label className="required">First Name</label>
                        <Field
                          name="firstName"
                          component={CustomInputComponent}
                          type="text"
                          disabled={editAccess}
                        />
                      </div>
                      <div className="col-md-6 form-group" style={{ opacity: editAccess ? 0.5 : 1 }}>
                        <label>Last Name</label>
                        <Field
                          name="lastName"
                          component={CustomInputComponent}
                          type="text"
                          disabled={editAccess}
                        />
                      </div>
                      <div className="col-md-6 form-group" style={{ opacity: editAccess ? 0.5 : 1 }}>
                        <label className="required">Email</label>
                        <Field
                          name="email"
                          component={CustomInputComponent}
                          type="text"
                          disabled={editAccess}
                        />
                      </div>
                      <div className="col-md-6 form-group" style={{ opacity: editAccess ? 0.5 : 1 }}>
                        <label className="required">Mobile Number</label>
                        <Field
                          name="mobile_number"
                          component={CustomInputComponent}
                          type="number"
                          disabled={editAccess}
                        />
                      </div>
                      <div className="col-md-6 form-group" style={{ opacity: editAccess ? 0.5 : 1 }}>
                        <label className="required">City</label>
                        <Field
                          name="city_name"
                          component={CustomInputComponent}
                          type="text"
                          disabled={editAccess}
                        />
                      </div>
                      <div className="col-md-6 form-group" style={{ opacity: editAccess ? 0.5 : 1 }}>
                        <label className="required">Linkedin Profile URL </label>
                        <Field
                          name="linkedln_page_url"
                          component={CustomInputComponent}
                          type="text"
                          placeholder="https://in.linkedin.com/url"
                          disabled={editAccess}
                        />
                      </div>
                    </div>
                    {editAccess ?
                      <div className="mt-3 text-center">
                        <button type="button" onClick={() => navigate("/my-profile/permanent-address?editable=" + editAccess)} className="theme-btn btn w-25">
                          Next
                        </button>
                      </div> : (
                        <div className="mt-3 text-center">
                          <button type="submit" className="theme-btn btn w-25">
                            Next
                          </button>
                        </div>
                      )}
                    {/* <div className="mt-3 text-center">
                      <button type="submit" className="theme-btn btn w-25">
                        Next
                      </button>
                    </div> */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UserDetailsView;
