import { Form, Field, FormikProvider, useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import * as Yup from 'yup';
import Card from '../../components/Card';
import { changePIN, clearChangePIN } from '../../store/actions/changePINAction';
import { CustomInputComponent } from '../../components/formik/FieldComponents';
import BtnSpinner from '../../utils/BtnSpinner';

const changePinValidationSchema = Yup.object().shape({
    currentpin: Yup.string().required('Please enter current PIN'),
    pin: Yup.string().required('Please enter PIN').matches(/^\d+$/, "Must be only digits").max(4, "Please enter 4 digit PIN").min(4, "Please enter 4 digit PIN"),
    confirmpin: Yup.string().required('Please confirm PIN').oneOf([Yup.ref('pin'), null], 'PIN and Confirm PIN must me same'),
})
const ChangePin = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const changePINResponse = useSelector(state => state.changePINReducer.data)
    const changePINLoading = useSelector(state => state.changePINReducer.loading)

    useEffect(() => {
        if (changePINResponse != null) {
            if (changePINResponse.data != null) {
                toast.success(changePINResponse.message)
                dispatch(clearChangePIN())
                navigate("/")
            }
        }
    }, [changePINResponse])

    const formik = useFormik({
        initialValues: {
            currentpin: '',
            pin: '',
            confirmpin: '',
        },
        validationSchema: changePinValidationSchema,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            let request = {
                old_pin: values.currentpin,
                pin: values.pin,
                confirm_pin: values.confirmpin
            }
            dispatch(changePIN(request))
        }
    });

    const onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <>
            {/* <ToastContainer /> */}
            <Card style={{ paddingLeft: '16px', paddingRight: '16px' }} title={"Change PIN"} backTo={"/"}>
                <div className='card-body'>
                    <FormikProvider value={formik} sx={{ flex: 1 }}>
                        <Form
                            onSubmit={formik.handleSubmit}
                            onKeyDown={onKeyDown}
                            className="custom-form"
                        >
                            <div className='row'>
                                <div className="col-md-12 form-group">
                                    <label className="required">Current Pin</label>
                                    <Field
                                        name="currentpin"
                                        component={CustomInputComponent}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-12 form-group">
                                    <label className="required">Pin</label>
                                    <Field
                                        name="pin"
                                        component={CustomInputComponent}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-12 form-group">
                                    <label className="required">Confirm Pin</label>
                                    <Field
                                        name="confirmpin"
                                        component={CustomInputComponent}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>

                            {
                                changePINLoading ? <BtnSpinner /> : <div className="mt-3 text-center">
                                <button type="submit" className="theme-btn btn w-25">
                                    Next
                                </button>
                            </div>
                            }
                        </Form>
                    </FormikProvider>
                </div>
            </Card>
        </>
    )
}

export default ChangePin