import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import rightArrow from "../../assets/img/right-arrow.svg";
import avtarImg from "../../assets/img/dummy-profile.png";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import backArrow from "../../assets/img/back.svg";
import { saveProfileImage } from '../../store/actions/saveProfileImageAction';
import DashboardLayout from '../../layouts/DashboardLayout';

const MyProfile = () => {

    const inputRef = useRef()
    const dispatch = useDispatch()

    const [profile_data, setProfileData] = useState([]);
    const [name, setName] = useState(null)
    const [profileImage, setProfileImage] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)

    const userData = Cookies.get("userData") ? JSON.parse(Cookies.get("userData")) : {};

    useEffect(() => {
        setProfileData(userData)
        // let firstname = userData.first_name;
        // let middlename = userData.middle_name;
        // let lastname = userData.last_name;
        // let fullname = null;
        // if (middlename == undefined) {
        //     fullname = firstname + ' ' + lastname
        // } else {
        //     fullname = firstname + ' ' + middlename + ' ' + lastname;
        // }
        setName(userData.name)
        // setProfileImage(userData.data.profile_image)
    }, [userData]);

    useEffect(() => {
        dispatch(saveProfileImage(profileImage))
        Cookies.remove("profileImage")
        Cookies.set("profileImage", profileImage)
    }, [profileImage])

    const handleFile = () => {
        inputRef.current.click()
    }

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        setSelectedFile(fileUploaded)
        console.log("fileUploaded", fileUploaded)
        let blockedExtensions = ["jpg", "jpeg", "png", "svg"]
        let extension = fileUploaded?.name.split(/[#?]/)[0].split(".").pop().trim()
        if (blockedExtensions.indexOf(extension) > -1) {
            let request = new FormData();
            request.append('profile_image', fileUploaded);
            request.append('email', profile_data?.email);
            request.append('mobile_number', profile_data?.mobile_number);
            request.append('phone_code', profile_data?.phone_code);
            request.append('linkedInProfileUrl', profile_data?.personalize_profile_static[0]?.linkedInProfileUrl ? profile_data?.personalize_profile_static[0]?.linkedInProfileUrl : "");
            console.log('profile_image', request)
            // dispatch(updateProfile(request));
        } else {
            toast.error("Only images are allowed")
        }
    };

    return (
        <DashboardLayout>
            <div className='header-mobile d-md-none border-b'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div>
                        <Link className="back-btn blue-text d-block" to="/dashboard">
                            <img src={backArrow} alt="arrow" /> My Profile
                        </Link>
                    </div>
                    <div>
                        <Link to="/change-pin" className='blue-btn'>Change Your PIN</Link>
                    </div>
                </div>
            </div>


            <div className='d-flex align-items-center justify-content-between cr-heading header-desktop'>
                <div className="md-title text-start fw-700">
                    My Profile
                </div>
                <div>
                    <Link to="/change-pin" className='theme-btn btn btn-sm'>Change Your PIN</Link>
                </div>
            </div>
            <div className="pt-4 pb-4 ps-5 pe-5">
                <div className='d-flex align-items-center profile-circle-flex'>
                    <div class="profile-circle-box d-inline-block position-relative  " >
                        <div class="profile-circle"><img src={profileImage != null ? profileImage : avtarImg} /></div>
                        <div class="pf-edit-btn" >
                            <i class="bi bi-pencil-fill"></i>
                            <input ref={inputRef} type="file" name="avatar" accept=".jpg, .jpeg, .png, .svg" style={{ display: "none" }} onChange={handleChange} /><button class="btn dashfile-upload-btn" type="button" onClick={handleFile} ></button>
                        </div>
                    </div>
                    <div className="ml-15">
                        <div className="md-title text-start font-1em">{name}</div>
                        <div className="sm-title text-start mt-1">{profile_data?.email}</div>
                        <div className="xs-title text-start mt-1">{profile_data?.lp_code}</div>
                    </div>
                </div>

                <ul className="home-tab-list">
                    <li>
                        <Link
                            to="/startup-details"
                            className="d-flex align-items-center"
                        >
                            <div className="hml-title">
                                Startup Details
                            </div>
                            <div className='ml-auto d-flex align-items-center'>
                                <div className="hm-arrow">
                                    <img src={rightArrow} alt="arrow" />
                                </div>
                            </div>
                        </Link>
                    </li>
                </ul>
            </div>
        </DashboardLayout>
    )
}

export default MyProfile