import { Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react'
import Clickable from '../../utils/Clickable';
import Pdf from "../../assets/img/pdf.png"
import doc2Icon from "../../assets/img/doc2.png"

export default function StartupDocumentPopup({ data }) {
    const theme = useTheme();
    console.log("data", data)
    return (
        <Paper sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '10px', overflowY: "auto", maxHeight: "90vh" }} >
            <h3 style={{ textAlign: 'center',    display: 'block', fontSize: "1.17rem", marginBlock: '1rem', marginInline: 0, fontWeight: "bold", unicodeBidi: "isolate" }}>Documents</h3>
            <ul className="document-list mt-2">
                {data?.map((item) => (
                    <li key={item.url} className="d-flex align-items-center">
                        <Clickable className="icon" onClick={() => window.open(item.url, 'blank')}>
                            <img className='ud-icon'
                                src={item?.type?.split('.').pop() == "docx" ? doc2Icon : Pdf}
                                alt="pdf"
                            />
                        </Clickable>
                        <Clickable className="ms-2" onClick={() => window.open(item.url, 'blank')}>
                            <div className="xs-title font-16 text-start">{item.name}</div>
                            <div className="sm-title font-12 text-start mt-1">{item.date_format}</div>
                        </Clickable>
                    </li>
                ))}
            </ul>
        </Paper>
    )
}
