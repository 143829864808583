import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import CommonHeader from './elements/CommonHeader';
import Sidebar from '../views/main/Sidebar';
import Customization from './Customization';
import { drawerWidth } from '../utils/util';
import { setMenu } from '../store/actions/setMenuAction';
import { ToastContainer } from 'react-toastify';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`

        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const CommonLayout = () => {
    const theme = useTheme();
    const navigation = useNavigate();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const customization = useSelector((state) => state.customization.data);
    // console.log("customization", customization)
    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.setMenuReducer.data);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch(setMenu(!leftDrawerOpened))
    };

    useEffect(() => {
        dispatch(setMenu(!matchDownMd))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: 'theme.palette.background.default',
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                {process.env.REACT_APP_SERVER && process.env.REACT_APP_SERVER == "demo" ? <div className='demo-server-block'>YOU ARE USING BACKRR SENDBOX ENVIRONMENT</div> : ""}
                <Toolbar sx={{ backgroundColor: '#F2F2F2', borderBottomLeftRadius: `${customization.borderRadius}px`, borderBottomRightRadius: `${customization.borderRadius}px` }}>
                    <CommonHeader handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened} >
                <ToastContainer />
                <Outlet />
            </Main>
            <Customization />
        </Box>
    );
};

export default CommonLayout;