import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { axiosClient } from "../../utils/helpers";
import Logout from "../../views/auth/Logout";

export const login = async (requestJson) => {
  try {
    const response = await axiosClient.post('startup-app/auth/login', requestJson);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const getUserInfo = async token => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('apiToken');
    const response = await axiosClient.get('startup-app/startup');
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const forgotPIN = async (requestJson) => {
  try {
    const response = await axiosClient.post('startup-app/auth/forgot-pin', requestJson);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const contactUs = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('apiToken');
    const response = await axiosClient.post('startup-app/setting/contact-us', requestJson);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const changePIN = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('apiToken');
    const response = await axiosClient.post('startup-app/startup/change-pin', requestJson);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};