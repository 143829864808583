export const regMatch = /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+=]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;

const ones = [
    '',
    'One ',
    'Two ',
    'Three ',
    'Four ',
    'Five ',
    'Six ',
    'Seven ',
    'Eight ',
    'Nine ',
    'Ten ',
    'Eleven ',
    'Twelve ',
    'Thirteen ',
    'Fourteen ',
    'Fifteen ',
    'Sixteen ',
    'Seventeen ',
    'Eighteen ',
    'Nineteen '
];
const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

export const convertToIndianCurrencyWords = (number) => {
    if (typeof number === 'number') {
        number = number.toString();
    }
    if (number === undefined || typeof number !== 'string') {
        return 'Please provide a valid input';
    }
    number = number.replace(/,/g, '');
    const parts = number.split('.');
    let integerPart = parts[0];
    number = integerPart;

    if ((number = number.toString()).length > 13) return '';
    const n = ('0000000000000' + number).substr(-13).match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

    if (!n) return '';


    let str = '';
    str += n[1] !== '00' ? (
        (ones[Number(n[1])] || tens[n[1][0]] + ' ' + ones[n[1][1]]) +
        (n[2] === "00" ? 'Thousand Crore ' : 'Thousand ')
    ) : '';


    str += n[2] !== '00' ? (
        (ones[Number(n[2])] || tens[n[2][0]] + ' ' + ones[n[2][1]]) +
        (n[3] === "00" ? 'Hundred Crore ' : 'Hundred ')
    ) : '';

    str += n[3] !== '00' ? (ones[Number(n[3])] || tens[n[3][0]] + ' ' + ones[n[3][1]]) + 'Crore ' : '';
    str += n[4] !== '00' ? (ones[Number(n[4])] || tens[n[4][0]] + ' ' + ones[n[4][1]]) + 'Lakh ' : '';
    str += n[5] !== '00' ? (ones[Number(n[5])] || tens[n[5][0]] + ' ' + ones[n[5][1]]) + 'Thousand ' : '';
    str += n[6] !== '0' ? (ones[Number(n[6])] || tens[n[6][0]] + ' ' + ones[n[6][1]]) + 'Hundred ' : '';
    str += n[7] !== '00' ? (str !== '' ? 'and ' : '') + (ones[Number(n[7])] || tens[n[7][0]] + ' ' + ones[n[7][1]]) : '';

    return str.trim() || null;
};

export const drawerWidth = 260;

export const gridSpacing = 10;