const BtnSpinner = ({ cls, loader }) => {
    return (
        <div className="mt-3 text-center btn-spinner">
        <button className={`${cls || 'theme-btn btn w-25 text-center'}`} disabled>
            <span className='spinner-border spinner-border-sm'></span>
            {loader ? '' : ' Loading...'}
        </button>
        </div>
    );
};


export default BtnSpinner;
