import React ,{useEffect}from "react";
import { Field, Form, Formik, FieldArray,FastField,ErrorMessage } from "formik";
import * as Yup from "yup";
import BtnSpinner from "../../utils/BtnSpinner";
import {
  CustomInputComponent,
  CustomSelectComponent,
  CustomRadioComponent,
  CustomCheckbox,
  CustomTextareaComponent
} from "../../components/formik/FieldComponents";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   UPDATE_PERSONALISED_INFORMATION,
//   SET_PAGE,
// } from "../../../../store/actions";
import {StartupForm } from "../../api";
import { Back } from "../../assets/img";
import { regMatch } from "../../utils/util";

const PersonalInformationValidationSchema = Yup.object().shape({
  company_name  : Yup.string().required("Startup name is required"),
  problem_solving: Yup.string().required("This field is required"),
  website_url: Yup.string().matches(regMatch, "Please enter a valid URL").required("Website URL is required"),
  founderData: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      linkedln_profile_url: Yup.string().url('Invalid linkedIn URL').required('LinkedIn profile is required'),
    })
  )
});




 const personalInformation = {
      company_name:"",
      problem_solving: "",
      website_url:"",
      founderData: [
          { name: '', linkedln_profile_url: '' } // Initial founder object
        ]
  };



function PersonalInformation({setStep}) {
//   const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startup_details, setStartupData] = React.useState(false);


  const {
    mutateAsync: startUpData,
    status: startUpStatus,
    error,
    isLoading,
  } = StartupForm();
  
  useEffect(() => {
    if (startUpStatus == 'success') {
      setStep(5)    
    }
    if (startUpStatus == 'error') {

          toast.error(error?.response?.data?.message);
    }
  }, [startUpStatus])


//   const {
//     mutateAsync: savePersonalInformation,
//     isLoading: isSavePersonalInformationLoading,
//   } = SavePersonalInformation();
//   const { data: countries, isLoading: isCountryLoading } = FetchAllCountries();
  // console.log("countries", countries);

//   const personalInformation = useSelector(
//     (state) => state.indianCitizenship.personalInformation
//   );

    useEffect(() => {
      // Retrieve the JSON string from localStorage
      const storedObject = localStorage.getItem('startup_details');

      // Parse the JSON string back into an object and set it in component state
      if (storedObject) {
        setStartupData(JSON.parse(storedObject));
      }
    }, []);


  return (
    <section className="mid-wrap">
      <div className="forms-header pt-3 pb-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-2 col-2">
              <Link   className="back-arrow">
                <img   onClick={()=> setStep(3)} src={Back} alt="back" />
              </Link>
            </div>
            <div className="col-md-8 col-8">
              <div className="lg-title text-center">Startup Information</div>
            </div>
            {/* <div className="col-md-2 col-2 text-end">
              <span className="pl-15 sm-title">2/4</span>
            </div> */}
          </div>
          <div className="text-center step-process mt-1">
            <div className="d-inline-flex">
              <span className="process-step active"></span>
              <span className="process-step selected"></span>
              <span className="process-step"></span>
              <span className="process-step"></span>
             
            </div>
          </div>
          {/* <h5 className="card-title md-title mt-2 mb-0 text-center heading-formobile">
            Personal Information
          </h5> */}
        </div>
      </div>
      <div className="pt-0 pb-5 kyc-form-block">
        <div className="container">
          <div className="card form-card mt-4">
            <div className="card-body">
              {/* <h5 className="card-title md-title mb-4 text-center heading-fordesktop">
                Personal Information
              </h5> */}
              <Formik
                initialValues={startup_details?startup_details:personalInformation}
                // validateOnChange={false}
                // validateOnBlur={true}
                enableReinitialize={true}
                validationSchema={PersonalInformationValidationSchema}
                onSubmit={(values) => {
                  localStorage.setItem('startup_details', JSON.stringify(values));
                  const storedUserObject = localStorage.getItem('user_details');
                  const UserObject =  JSON.parse(storedUserObject)
                  // values.founderData =  JSON.stringify(values.founderData)
                  const data =  {"entity_name" :UserObject.firstName+ " "+UserObject.lastName, ...UserObject, ...values};

                  startUpData(data)
                  // console.log(data,"valuesvaluesvaluesvaluesvaluesvaluesvaluesvalues")
                }}
              >
                {({ errors, touched, values }) => (
                  <Form className="custom-form">
                    <div className="row">
                      {/* First Name */}
                      <div className="col-md-12 form-group">
                        <label className="required">Startup Name</label>
                        <Field
                          name="company_name"
                          component={CustomInputComponent}
                          type="text"
                        />
                      </div>

                      {/* Middle Name */}
                      <div className="col-md-12 form-group">
                      <label className="required">What Problem You Are Solving?</label>
                        <Field
                          name="problem_solving"
                          component={CustomTextareaComponent}
                          type="text"
                        />
                      </div>
                        {/* Referral partner */}
                        <div className="col-md-12 form-group">
                        <label className="required">Website URL </label>
                        <Field
                          name="website_url"
                          component={CustomInputComponent}
                          placeholder="https://www.website.com/"
                          type="text"
                        />
                      </div>


                      <FieldArray
                        name="founderData"
                        render={({ insert, remove, push }) => (
                          <>
                          <div className="cs-card">
                            {values.founderData.length > 0 &&
                              values.founderData.map((founder, index) => (
                                <div className="card">
                                  <div className="ps-3 pe-3 pt-3 pb-0">
                                  {index > 0 && <button type="button"  className="remove-btn" onClick={() => remove(index)}  data-bs-toggle="tooltip" data-bs-placement="top"
                                          data-bs-custom-class="custom-tooltip"
                                          data-bs-title="Add Founders">
                                          <i class="bi bi-x-circle-fill"></i>
                                        </button>}
                                    <div className="row" key={index}>
                                      {/* Names of all Founders */}
                                      <div className="col-md-6 col-12 form-group">
                                        <label className="required">Co-founder Name {index +1 }</label>
                                        <Field
                                          name={`founderData.${index}.name`}
                                          type="text"
                                          component={CustomInputComponent}
                                        />
                                        <ErrorMessage
                                        name={`founderData.${index}.name`}
                                        component="div"
                                        style={{ color: "red" }}
                                      />
                                      </div>
                                      {/* Linkedin Profile of all Founders */}
                                      <div className="col-md-6 col-12 form-group">
                                        <label className="required">Linkedin Profile URL </label>
                                        <Field
                                          name={`founderData.${index}.linkedln_profile_url`}
                                          type="text"
                                          component={CustomInputComponent}
                                          placeholder="https://in.linkedin.com/url"
                                        />
                                        <ErrorMessage
                                        name={`founderData.${index}.linkedln_profile_url`}
                                        component="div"
                                        style={{ color: "red" }}
                                      />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            {/* Add button */}
                            {/* <button className="add-investment-btn" type="button"><i class="bi bi-plus-circle"></i> &nbsp;&nbsp;Add Investments</button> */}
                          </div>
                          <div className="mt-2 text-end">
                            <button
                              type="button"  className="add-btn mt-2"   title="Add More Co-founders Name
                              " data-bs-toggle="tooltip" data-bs-placement="top"
                              data-bs-custom-class="custom-tooltip"
                              data-bs-title="Add Founders"
                              onClick={() => push({ name: '', linkedln_profile_url: '' })}
                            >
                              <i class="bi bi-plus-circle-fill"></i>
                            </button>
                          </div>
                          </>
                        )}
                      />
                    </div>
                      <div className="mt-3 text-center">
                        <button type="submit" className="theme-btn btn w-25">
                          Next
                        </button>
                      </div>
                    {/* )} */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          {/* <div className="text-center mt-30">
            <a href="#" className="white-btn w-360">
              Terms of Use
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default PersonalInformation;
