import Cookies from "js-cookie";
import DashboardLayout from "../../layouts/DashboardLayout";

const Dashboard = () => {

    const userDetail = Cookies.get("userData") ? JSON.parse(Cookies.get("userData")) : {};

    return (
        <div className="main-card card">
            
        </div>
    );
};

export default Dashboard;