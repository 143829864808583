import { types } from './types';

export function getUserInfo(data) {
    return {
        type: types.SEND_REQUEST_GET_USER_INFO,
        payload: data,
    };
}

export function getUserInfoSuccess(data) {
    return {
        type: types.SEND_REQUEST_GET_USER_INFO_SUCCESS,
        payload: data,
    };
}

export function getUserInfoFailure(error) {
    return {
        type: types.SEND_REQUEST_GET_USER_INFO_FAILURE,
        payload: {},
        error: error,
    };
}

export function clearGetUserInfo() {
    return {
        type: types.CLEAR_REQUEST_GET_USER_INFO,
        payload: {},
    };
}