import React, { useEffect } from 'react';
import * as Yup from 'yup';
import AuthBtnSpinner from '../../utils/AuthBtnSpinner';
import { Field, Form, Formik } from 'formik';
import logoVr from '../../assets/img/logo_vr.png';
import { Link, useNavigate } from 'react-router-dom';
import { CustomInputComponent } from '../../components/formik/FieldComponents';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { clearForgotPIN, forgotPIN } from '../../store/actions/forgotPINAction';

const EmailValidationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Must be a valid email')
});

function ForgotPin() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const forgotPINResponse = useSelector(state => state.forgotPINReducer.data)
    const loadingForgotPIN = useSelector(state => state.forgotPINReducer.loading)

    const forgotPINAPI = (values) => {
        let request = {
            email: values.email
        }
        dispatch(forgotPIN(request))
    }

    useEffect(() => {
        if (forgotPINResponse != null) {
            if (Object.keys(forgotPINResponse).length != 0 && forgotPINResponse.statusCode != 200) {
                toast.error(forgotPINResponse.message)
                dispatch(clearForgotPIN())
            }
            if (Object.keys(forgotPINResponse).length != 0 && forgotPINResponse.statusCode == 200) {
                toast.success(forgotPINResponse.message)
                dispatch(clearForgotPIN())
                navigate('/login')
            }
        }
    }, [forgotPINResponse])

    return (

        <section className="mid-wrap pt-5 pb-5">
            <div className="mid-card-block">
                <div className="mxw-750">
                    <div className="text-center mb-4 vendor-info">
                        <img src={logoVr} alt="my mandi" />
                    </div>
                    <div className="card form-card">
                        <div className="card-body">
                            <h5 className="card-title lg-title fw-700">Forgot Pin</h5>
                            <p className="sm-title mb-4 mt-3">We will sent you a Pin. Please enter your registered email address.</p>

                            <Formik
                                initialValues={{
                                    email: '',

                                }}
                                validationSchema={EmailValidationSchema}
                                onSubmit={(values) => { forgotPINAPI(values) }}
                                validateOnBlur={true}
                                validateOnChange={true}
                            >

                                {({ errors, touched }) => (

                                    // Contact Us Form
                                    <Form className="custom-form">
                                        <div className="form-group">
                                            <label>Email address</label>
                                            <Field name="email" component={CustomInputComponent} type="text" className="form-control" />

                                        </div>
                                        {loadingForgotPIN ? (
                                            <AuthBtnSpinner />
                                        ) : (
                                            <button className='theme-btn btn w-100' type='submit'>
                                                Send
                                            </button>
                                        )}

                                        <div className="sm-title mt-4 fw-500">
                                            Already have an account? <Link to='/login'><u>Sign In</u></Link>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )

}

export default ForgotPin