import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import DashboardLayout from '../../../layouts/DashboardLayout';
import Card from "../../../components/Card"
import { Button, Chip, Tooltip, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { CheckCircle, DescriptionOutlined } from '@mui/icons-material';
import { IconEdit } from '@tabler/icons';

export default function ChecklistListing() {

    const investorDataChecklist = {
        "statusCode": 200,
        "message": "Check list fetch successfully",
        "data": [
            {
                "name": "Cello World",
                "task_name": "Test Checklist for Startup T1",
                "task_status": "undefined",
                "checklist_master_id": "65c4aa2bd58ae167e6f9345b",
                "task_id": "65c611ac192bce2be159e1f2",
                "eta_date": "08-Feb-2024",
                "dateArr": {
                    "February 2024": false,
                    "January 2024": false,
                    "December 2023": false,
                    "November 2023": false,
                    "October 2023": false,
                    "September 2023": false,
                    "August 2023": false,
                    "July 2023": false,
                    "June 2023": false,
                    "May 2023": false,
                    "April 2023": false,
                    "March 2023": false,
                    "February 2023": false
                }
            },
            {
                "name": "Cello World",
                "task_name": "Test Checklist for Startup T2",
                "checklist_master_id": "65c4aa2bd58ae167e6f9345b",
                "task_id": "65c611ac192bce2be159e1f3",
                "eta_date": "08-Feb-2024",
                "dateArr": {
                    "February 2024": false,
                    "January 2024": false,
                    "December 2023": false,
                    "November 2023": false,
                    "October 2023": false,
                    "September 2023": false,
                    "August 2023": false,
                    "July 2023": false,
                    "June 2023": false,
                    "May 2023": false,
                    "April 2023": false,
                    "March 2023": false,
                    "February 2023": false
                }
            },
            {
                "name": "Cello World",
                "task_name": "Test Check",
                "checklist_master_id": "65c4aa2bd58ae167e6f9345b",
                "task_id": "65c611ac192bce2be159e1f4",
                "eta_date": "09-Feb-2024",
                "dateArr": {
                    "February 2024": false,
                    "January 2024": false,
                    "December 2023": false,
                    "November 2023": false,
                    "October 2023": false,
                    "September 2023": false,
                    "August 2023": false,
                    "July 2023": false,
                    "June 2023": false,
                    "May 2023": false,
                    "April 2023": false,
                    "March 2023": false,
                    "February 2023": false
                }
            },
            {
                "name": "Cello World",
                "task_name": "Test Check 2",
                "checklist_master_id": "65c4aa2bd58ae167e6f9345b",
                "task_id": "65c611ac192bce2be159e1f5",
                "eta_date": "09-Feb-2024",
                "dateArr": {
                    "February 2024": false,
                    "January 2024": false,
                    "December 2023": false,
                    "November 2023": false,
                    "October 2023": false,
                    "September 2023": false,
                    "August 2023": false,
                    "July 2023": false,
                    "June 2023": false,
                    "May 2023": false,
                    "April 2023": false,
                    "March 2023": false,
                    "February 2023": false
                }
            },
            {
                "name": "Cello World",
                "task_name": "Test Check 3",
                "checklist_master_id": "65c4aa2bd58ae167e6f9345b",
                "task_id": "65c611ac192bce2be159e1f6",
                "eta_date": "09-Feb-2024",
                "dateArr": {
                    "February 2024": false,
                    "January 2024": false,
                    "December 2023": false,
                    "November 2023": false,
                    "October 2023": false,
                    "September 2023": false,
                    "August 2023": false,
                    "July 2023": false,
                    "June 2023": false,
                    "May 2023": false,
                    "April 2023": false,
                    "March 2023": false,
                    "February 2023": false
                }
            }
        ],
        "headers": [
            "February 2024",
            "January 2024",
            "December 2023",
            "November 2023",
            "October 2023",
            "September 2023",
            "August 2023",
            "July 2023",
            "June 2023",
            "May 2023",
            "April 2023",
            "March 2023",
            "February 2023"
        ]
    }

    const tamplate_data = {
        "checklist_period": "monthly",
        "tasks": [
            {
                "required": false,
                "task_name": "P- Principle Calculation ",
                "task_description": "Principle Calculation request",
                "display_order": 1,
                "eta_date": "2024-02-08T10:06:05.167Z",
                "task_id": "65c4b2e0a9b57f47247807b5",
                "createdAt": "08-Feb-2024",
                "updatedAt": "08-Feb-2024 4:24PM",
                "Drive": [
                    {
                        "drive_id": "658e9ae118cd914cc987f79f",
                        "document_date": "2024-02-01",
                        "created_by": "65b7397e7d179c06f5122e93",
                        "created_date": "2024-02-08T10:06:49.999Z"
                    }
                ],
                "completed_date": "1970-01-01T00:00:00.000Z",
                "current_status": "undefined",
                "date": "undefined",
                "drive_document_date": [
                    "2024-02"
                ],
                "drive_id": [
                    "658e9ae118cd914cc987f79f"
                ],
                "file_url": [
                    "test"
                ],
                "notes": "test",
                "task_status": "undefined",
                "updated_by": {
                    "_id": "65b7397e7d179c06f5122e93",
                    "name": "Harsh Yadav"
                },
                "url": "",
                "completed_date_format": "01-Jan-1970",
                "eta_date_format": "08-Feb-2024",
                "document_date_format": "",
                "last_updated_by": "Harsh Yadav",
                "document": [
                    {
                        "filename": "test.xlsx",
                        "url": "https://backrr-private-dev.s3.ap-south-1.amazonaws.com/drive/test.xlsx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWNPMFCGAKI42EN6Q%2F20240213%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240213T090427Z&X-Amz-Expires=300&X-Amz-Signature=8e22fd7f19820d97b64e2b3ae5030e368f7861f9081234e64bfb54d6a2b890c4&X-Amz-SignedHeaders=host"
                    }
                ]
            },
            {
                "required": true,
                "task_name": "jh",
                "task_description": "jh",
                "display_order": 2,
                "task_date": "",
                "eta_date": "2024-02-08T10:53:01.764Z",
                "task_id": "65c4b2e0a9b57f47247807b6",
                "createdAt": "08-Feb-2024",
                "updatedAt": "08-Feb-2024 4:24PM",
                "url": "",
                "completed_date_format": "",
                "eta_date_format": "08-Feb-2024",
                "document_date_format": "",
                "last_updated_by": ""
            },
            {
                "required": false,
                "task_name": "",
                "task_description": "",
                "display_order": 3,
                "task_date": "",
                "eta_date": "2024-02-08T10:54:05.706Z",
                "task_id": "65c4b2e0a9b57f47247807b7",
                "createdAt": "08-Feb-2024",
                "updatedAt": "08-Feb-2024 4:24PM",
                "completed_date_format": "",
                "eta_date_format": "08-Feb-2024",
                "document_date_format": "",
                "last_updated_by": ""
            },
            {
                "required": false,
                "task_name": "",
                "task_description": "",
                "display_order": 4,
                "task_date": "",
                "eta_date": "2024-02-08T10:54:05.841Z",
                "task_id": "65c4b2e0a9b57f47247807b8",
                "createdAt": "08-Feb-2024",
                "updatedAt": "08-Feb-2024 4:24PM",
                "completed_date_format": "",
                "eta_date_format": "08-Feb-2024",
                "document_date_format": "",
                "last_updated_by": ""
            }
        ],
        "_id": "65c4b2e0a9b57f47247807b9",
        "checklist_master_id": "65c49b16d58ae167e6f8e761",
        "template_name": "Monthely Calculations and Update"
    }

    // const tamplate_data = {
    //     "checklist_period": "onetime",
    //     "tasks": [
    //         {
    //             "required": false,
    //             "task_name": "Test Checklist for Startup Cello World 1",
    //             "task_description": "Testing 1",
    //             "display_order": 1,
    //             "eta_date": "2024-02-13T06:47:11.121Z",
    //             "task_id": "65cb114636e83236dcfe786b",
    //             "createdAt": "13-Feb-2024",
    //             "updatedAt": "13-Feb-2024 12:20PM",
    //             "url": "",
    //             "completed_date_format": "",
    //             "eta_date_format": "13-Feb-2024",
    //             "document_date_format": "",
    //             "last_updated_by": ""
    //         },
    //         {
    //             "required": false,
    //             "task_name": "Test Checklist for Startup Cello World 2",
    //             "task_description": "Testing 2",
    //             "display_order": 2,
    //             "eta_date": "2024-02-13T06:47:11.122Z",
    //             "task_id": "65cb114636e83236dcfe786c",
    //             "createdAt": "13-Feb-2024",
    //             "updatedAt": "13-Feb-2024 12:20PM",
    //             "url": "",
    //             "completed_date_format": "",
    //             "eta_date_format": "13-Feb-2024",
    //             "document_date_format": "",
    //             "last_updated_by": ""
    //         }
    //     ],
    //     "_id": "65cb114636e83236dcfe786d",
    //     "checklist_master_id": "65cb10072a3d2d26825be112",
    //     "template_name": "Checklist Template for Startup Cello World"
    // }

    return (
        <>
            {/* <ToastContainer /> */}
            <Card style={{ paddingLeft: '16px', paddingRight: '16px' }} title={"Checklist"} backTo={"/dashboard"}>
                {
                    tamplate_data.checklist_period != "monthly" ?
                        <div className='custom-card card'>
                            <div className='card-body'>
                                <div className='custom-table table-responsive mt-3'>
                                    <table className='w-100 table add-border'>
                                        <thead>
                                            <tr >
                                                <th>&nbsp;</th>
                                                <th className='text-start text-nowrap' >Task Name</th>
                                                <th className='text-start text-nowrap'>Task Description</th>
                                                <th className='text-center text-nowrap'>Required</th>
                                                <th className='text-center text-nowrap'>ETA Date</th>
                                                {tamplate_data.checklist_period != 'monthly' &&
                                                    <th className='text-center text-nowrap'>Completed Date</th>
                                                }
                                                <th className='text-center text-nowrap'>Updated</th>
                                                <th className='text-center text-nowrap'>Updated By</th>
                                                <th className='text-center text-nowrap'>Status</th>
                                                <th className='text-center text-nowrap'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tamplate_data.tasks && tamplate_data.tasks.length > 0 ? (
                                                tamplate_data.tasks.map((task, index) => (
                                                    <tr>
                                                        <td>
                                                            {task.task_status == "Completed" ? <div style={{ color: '#60cb5e' }}><CheckCircle /></div> : ""}
                                                            {task.task_status == "pending" ? <div style={{ color: '#616161' }}><CheckCircle /></div> : ""}
                                                            {task.task_status == "inProgress" ? <div style={{ color: '#d4a72d' }}><CheckCircle /></div> : ""}
                                                            {(!task.task_status) || task.task_status == "undefined" ? <div style={{ color: '#616161' }}><CheckCircle /></div> : ""}
                                                        </td>
                                                        <td className='text-start'>{task.task_name}</td>
                                                        <td className='text-start'>{task.task_description}</td>
                                                        <td className='text-center' >{task.required ? "Yes" : "No"}</td>
                                                        <td className='text-center' style={{ whiteSpace: "nowrap" }} >{task.eta_date_format}</td>
                                                        {tamplate_data.checklist_period != 'monthly' &&
                                                            <td className='text-center' >{task.task_status == "Completed" ? task.completed_date_format : ""}</td>
                                                        }
                                                        <td className='text-center' style={{ whiteSpace: "nowrap" }} >{task.updatedAt != "" ? task.updatedAt : ""}</td>
                                                        <td className='text-center' style={{ whiteSpace: "nowrap" }}>{task.last_updated_by != "" ? task.last_updated_by : ""}</td>
                                                        <td className='text-center'> {task.task_status == "Completed" ? <Chip
                                                            size="small"
                                                            label="Completed"
                                                            sx={{
                                                                backgroundColor: '#ebfaeb',
                                                                color: '#60cb5e',
                                                                border: 'none',
                                                                minWidth: '100px',
                                                                fontSize: '12px',
                                                                borderRadius: '4px',
                                                                fontWeight: 700,
                                                            }}
                                                        /> : ""}
                                                            {task.task_status == "pending" ? <Chip
                                                                size="small"
                                                                label="Pending"
                                                                sx={{
                                                                    backgroundColor: '#efefef',
                                                                    color: '#616161',
                                                                    border: 'none',
                                                                    minWidth: '100px',
                                                                    fontSize: '12px',
                                                                    borderRadius: '4px',
                                                                    fontWeight: 700,
                                                                }}
                                                            /> : ""}

                                                            {task.task_status == "inProgress" ? <Chip
                                                                size="small"
                                                                label="In Progress"
                                                                sx={{
                                                                    backgroundColor: '#fef8e8',
                                                                    color: '#d4a72d',
                                                                    border: 'none',
                                                                    minWidth: '100px',
                                                                    fontSize: '12px',
                                                                    borderRadius: '4px',
                                                                    fontWeight: 700,
                                                                }}
                                                            /> : ""}

                                                            {(!task.task_status) || task.task_status == "undefined" ? <Chip
                                                                size="small"
                                                                label="Pending"
                                                                sx={{
                                                                    backgroundColor: '#efefef',
                                                                    color: '#616161',
                                                                    border: 'none',
                                                                    minWidth: '100px',
                                                                    fontSize: '12px',
                                                                    borderRadius: '4px',
                                                                    fontWeight: 700,
                                                                }}
                                                            /> : ""}
                                                        </td>
                                                        <td className='text-center'>
                                                            <div style={{ display: "flex" }}>
                                                                <button type='button' className='icon-btn'
                                                                    onClick={() => { }}><IconEdit />
                                                                </button>
                                                                {task.document && task.document.length > 0 &&
                                                                    <Tooltip title="Documents">
                                                                        <button type="button" className="icon-btn" onClick={() => { }}>
                                                                            <DescriptionOutlined />
                                                                        </button>
                                                                    </Tooltip>}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <>
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        : <div className='custom-table table-responsive lp-summary-table investor-onboarding-table'>
                            <table className='w-100 table add-border' id="target">
                                {investorDataChecklist?.data?.length > 0 && (
                                    <thead>
                                        <tr>
                                            <th>Task Name</th>
                                            {investorDataChecklist?.headers.map((header, index) => (
                                                <th>{header}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                )}
                                <tbody>
                                    {investorDataChecklist?.data?.length > 0 ? (
                                        investorDataChecklist?.data.map((partner, partnerIndex) => (
                                            <tr key={partnerIndex}>
                                                <td>{partner?.task_name}</td>
                                                {Object.entries(partner?.dateArr).map(([date, value], dateIndex) => (
                                                    <td style={{ padding: 0 }} title={`ETA: ${partner?.eta_date.split('-').map((item, index) => {
                                                        if (index === 1) { return date.slice(0, 3) } else { return item }
                                                    }).join('-')}`} key={dateIndex}>
                                                        {value ?
                                                            <Button sx={{ width: '100%', minHeight: 50, borderRadius: 0 }}
                                                            // onClick={() => handleOpen(partner, date)}
                                                            >
                                                                <CheckIcon style={{ color: 'green' }} />
                                                            </Button>
                                                            :
                                                            <Button sx={{ width: '100%', minHeight: 50, borderRadius: 0 }}
                                                            // onClick={() => handleOpen(partner, date)}
                                                            >
                                                                <CloseIcon style={{ color: 'red' }} />
                                                            </Button>
                                                        }
                                                    </td>

                                                ))}
                                            </tr>
                                        ))

                                    ) : (
                                        <tr>
                                            <td className='text-center' colSpan={5}><Typography variant="h4">Select</Typography></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                }
            </Card>
        </>
    );
}