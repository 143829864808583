// project imports
// import config from 'config';

// action - state management
import config from '../../config';
import { types } from '../actions/types';

export const initialState = {
    data: {},
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.CUSTOMISATION:
            return {
                ...state,
                data: payload,
            };
        default:
            return state;
    }
};

export default customizationReducer;